import {
  POST_ADD_NOTIFICATION_SUCCESS,
  POST_ADD_NOTIFICATION_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  notify: [],
}

const Calendar = (state = INIT_STATE, action) => {
  switch (action.type) {

    case POST_ADD_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notify: [...state.notify, action.payload],
      }

    case POST_ADD_NOTIFICATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Calendar
