import React from "react"
import { Col, Input, Row, Form } from "reactstrap"

const SearchForm = props => {
  const {
    setFilterSiteManager,
    filterSiteManager,
    handleSubmit,
    removeValuesManagerName,
    setFilterSiteName,
    filterSiteName,
    removeValuesSiteName,
    setFilterContactNumber,
    filterContactNumber,
    removeValuesContactNumber,
    handleOrSiteClicks,
    setFilterAddress,
    filterAddress,
    removeValuesAddress,
    isAddOptions,
  } = props
  return (
    <div className="d-flex">
      <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <Row className="mb-2">
          <Col className=" justify-content-start">
            <Input
              type="text"
              className="mb-2"
              placeholder="Search for Site Name"
              value={filterSiteName || ""}
              onChange={e => {
                setFilterSiteName(e.target.value || undefined)
              }}
            />
            <button
              type="button"
              className="close"
              aria-label="Close"
              style={{ marginTop: "-3px", marginRight: "10px" }}
              onClick={removeValuesSiteName}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Col>
          <Col className=" justify-content-start">
            <Input
              type="text"
              className="mb-2"
              placeholder="Search for Site Manager"
              value={filterSiteManager || ""}
              onChange={e => {
                setFilterSiteManager(e.target.value || undefined)
              }}
            />
            <button
              type="button"
              className="close"
              aria-label="Close"
              style={{ marginTop: "-3px", marginRight: "10px" }}
              onClick={removeValuesManagerName}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Col>
          {/* <Col className=" justify-content-start">
            <Input
              type="text"
              className="mb-2"
              placeholder="Search for Contact Number"
              value={filterContactNumber || ""}
              onChange={e => {
                setFilterContactNumber(e.target.value || undefined)
              }}
            />
            <button
              type="button"
              className="close"
              aria-label="Close"
              style={{ marginTop: "-3px", marginRight: "10px" }}
              onClick={removeValuesContactNumber}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Col>
          <Col className=" justify-content-start">
            <Input
              type="text"
              className="mb-2"
              placeholder="Search for Address"
              value={filterAddress || ""}
              onChange={e => {
                setFilterAddress(e.target.value || undefined)
              }}
            />
            <button
              type="button"
              className="close"
              aria-label="Close"
              style={{ marginTop: "-3px", marginRight: "10px" }}
              onClick={removeValuesAddress}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Col> */}

          <Col className="justify-content-start">
            <button
              type="submit"
              className="btn btn-primary save-customer me-2"
              onClick={handleSubmit}
            >
              Search
            </button>
          </Col>
        </Row>
      </Form>
      <>
        {isAddOptions && (
          <div style={{ position: "absolute", right: "16px" }}>
            <button
              type="submit"
              className="btn btn-primary save-customer me-2"
              onClick={handleOrSiteClicks}
            >
              + Add New Sites
            </button>
          </div>
        )}
      </>
    </div>
  )
}
export default SearchForm
