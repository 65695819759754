import React from "react"
import { takeEvery, put, call, patch } from "redux-saga/effects"
import toastr from "toastr"
import { togglePageLoader } from "store/actions"

// Chat Redux States
import {
  GET_SITES,
  POST_ADD_SITE,
  POST_UPDATE_SITE,
  POST_DELETE_SITE,
  GET_CLOCK,
  POST_ADD_CLOCK,
  POST_DELETE_ATTC,
  POST_UP_CLOCK,
  GET_CHECK_IN
} from "./actionTypes"
import {
  getSitesSuccess,
  getSitesFail,
  addSiteSuccess,
  addSiteFail,
  delSiteSuccesss,
  delSiteFails,
  upSiteSuccesss,
  upSiteFails,
  addClockFail,
  getClockFail,
  getClockSuccess,
  addClockSuccess,
  delAttcSuccesss,
  delAttcFails,
  upClockSuccess,
  upClockFail,
  getCheckInSuccess,
  getCheckInFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  getSites,
  addSite,
  upSites,
  deleteSites,
  getClock,
  addClocks,
  delAttcs,
  upClocks,
  getCheckInStatus
} from "../../helpers/site_backend_helper"
import { formtojson } from "helpers/util"
import { Redirect } from "react-router-dom"

function* onGetSites({ payload: data }) {
  try {
    const response = yield call(getSites, data)
    yield put(getSitesSuccess(response))
    yield put(addClockSuccess(null))
    yield put(upClockSuccess(null))
  } catch (error) {
    yield put(getSitesFail(error))
    // toastr.error(error?.response ? error.response.message :error)
    if(error?.response?.status == 401){
      localStorage.removeItem("authUser")
      localStorage.removeItem("authTokenDisplay");
      <Redirect to="/logout"/> 
    }
  }
}

function* onAddSite({ message, callback }) {
  try {
    yield put(togglePageLoader(true))
    const response = yield call(addSite, message)
    if (response.statusCode == -1) {
      toastr.error(response?.statusMessage)
      yield put(addSiteSuccess({ ...formtojson(message), ...response }))
      yield put(togglePageLoader(false))
      callback("FAILURE")
    } else {
      yield put(addSiteSuccess({ ...formtojson(message), ...response }))
      toastr.success(response.statusMessage)
      callback("SUCCESS")
      yield put(togglePageLoader(false))
    }
    setTimeout(() => toastr.clear(), 3000)
    yield put(togglePageLoader(false))
  } catch (error) {
    toastr.error(error)
    setTimeout(() => toastr.clear(), 3000)
    yield put(togglePageLoader(false))
    yield put(addSiteFail(error))
    callback("FAILURE")
    if(error?.response?.status == 401){
      localStorage.removeItem("authUser")
      localStorage.removeItem("authTokenDisplay");
      <Redirect to="/logout"/> 
    }
  }
}

function* updateSites({ data, callback }) {
  try {
    yield put(togglePageLoader(true))
    const response = yield call(upSites, data)
    if (response.statusCode == -1) {
      yield put(togglePageLoader(true))
      toastr.error(response?.statusMessage)
      yield put(togglePageLoader(false))
      yield put(upSiteSuccesss({ ...formtojson(data), ...response }))
      callback("FAILURE")
    } else {
      yield put(upSiteSuccesss({ ...formtojson(data), ...response }))
      toastr.success(response?.statusMessage)
      callback("SUCCESS")
      yield put(togglePageLoader(false))
    }
    setTimeout(() => toastr.clear(), 3000)
  } catch (error) {
    toastr.error(error)
    setTimeout(() => toastr.clear(), 3000)
    yield put(togglePageLoader(false))
    yield put(upSiteFails(error))
    callback("FAILURE")
  }
}
function* deleteSite({ id }) {
  try {
    yield put(togglePageLoader(true))
    const response = yield call(deleteSites, id)
    toastr.success("Site Deleted Successfully")
    setTimeout(() => toastr.clear(), 3000)
    yield put(delSiteSuccesss(id))
    yield put(togglePageLoader(false))
  } catch (error) {
    toastr.error(error)
    setTimeout(() => toastr.clear(), 3000)
    yield put(togglePageLoader(false))
    yield put(delSiteFails(error))
  }
}
//clock in and clock out
function* onGetClock({ payload: data }) {
  try {
    yield put(togglePageLoader(true))
    const response = yield call(getClock, data)
    yield put(getClockSuccess(response))
    yield put(togglePageLoader(false))
  } catch (error) {
    yield put(getClockFail(error))
    yield put(togglePageLoader(false))
    // toastr.error(error?.response ? error.response.message :error)
    if(error?.response?.status == 401){
      localStorage.removeItem("authUser")
      localStorage.removeItem("authTokenDisplay");
      <Redirect to="/logout"/> 
    }
  }
}

function* onAddClock({ message }) {
  try {
    yield put(togglePageLoader(true))
    const response = yield call(addClocks, message)
    if (response.statusCode == -1) {
      toastr.error(response?.statusMessage)
      yield put(togglePageLoader(false))
    } else {
      yield put(addClockSuccess({ ...message, ...response }))
      yield put(togglePageLoader(false))
      toastr.success("Clock In Successfully")
    }
    setTimeout(() => toastr.clear(), 3000)
    yield put(togglePageLoader(false))
  } catch (error) {
    toastr.error(error)
    setTimeout(() => toastr.clear(), 3000)
    yield put(togglePageLoader(false))
    yield put(addClockFail(error))
  }
}
function* upAddClock({ message }) {
  try {
    yield put(togglePageLoader(true))
    const response = yield call(upClocks, message)
    if (response.statusCode == -1) {
      toastr.error(response?.statusMessage)
      yield put(togglePageLoader(false))
    } else {
      yield put(upClockSuccess({ ...message, ...response }))
      toastr.success("Clock Out Successfully")
      yield put(togglePageLoader(false))
    }
    setTimeout(() => toastr.clear(), 3000)
    yield put(togglePageLoader(false))
  } catch (error) {
    toastr.error(error)
    setTimeout(() => toastr.clear(), 3000)
    yield put(togglePageLoader(false))
    yield put(upClockFail(error))
  }
}

function* deleteAttc({ id }) {
  try {
    const response = yield call(delAttcs, id)
    yield put(delAttcSuccesss({ ...response, ...id }))
  } catch (error) {
    toastr.error(error)
    yield put(delAttcFails(error))
  }
}

function* onGetCheckInStatus({payload:id}) {
  try {
    const response = yield call(getCheckInStatus,id)
    yield put(getCheckInSuccess(response))
   
  } catch (error) {
    yield put(getCheckInFail(error))
  }
}

function* siteSaga() {
  yield takeEvery(GET_SITES, onGetSites)
  yield takeEvery(POST_ADD_SITE, onAddSite)
  yield takeEvery(POST_UPDATE_SITE, updateSites)
  yield takeEvery(POST_DELETE_SITE, deleteSite)
  yield takeEvery(POST_ADD_CLOCK, onAddClock)
  yield takeEvery(POST_UP_CLOCK, upAddClock)
  yield takeEvery(GET_CLOCK, onGetClock)
  yield takeEvery(POST_DELETE_ATTC, deleteAttc)
  yield takeEvery(GET_CHECK_IN, onGetCheckInStatus)
}

export default siteSaga
