import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Input,
  Label,
  Alert,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { getSOS, addSOS, updateSOS, toggleModal } from "store/actions"

const AddSosModal = ({ show, onCloseClick }) => {
  const dispatch = useDispatch()
  const [sosnumber, setSosNumber] = useState("")
  const [isValid, setIsValid] = useState(true)
  const [successMessage, setSuccessMessage] = useState("")
  const { sos_number, all,toggle_modal } = useSelector(state => ({
    sos_number: state.Dashboard?.SosNumber,
    all: state,
    toggle_modal:state.Dashboard?.toggleModal
  }))
  useEffect(() => {
    dispatch(getSOS())
  }, [])

  useEffect(() => {
    if (sos_number !== undefined) {
      const sos = sos_number?.setting?.sos_Number
      setSosNumber(sos)
    }
   
  }, [sos_number])


  useEffect(() => {
     if(toggle_modal === false){
      onCloseClick();
      dispatch(toggleModal())
     }
  }, [toggle_modal])

  const handleOnChange = e => {
    setSosNumber(e.target.value)
  }

  const handleSubmit = e => {
    // Regular expression for basic phone number validation
    const phoneRegex = /^[0-9]{11}$/
    const checksos = phoneRegex.test(sosnumber)
    // Test the input telephone against the regular expression
    setIsValid(checksos)
    if (!checksos) {
      return false
    }
    const data = {
      sos_Number: sosnumber,
    }
    if (sos_number !== undefined) {
      data.id = sos_number?.setting?.id
      dispatch(updateSOS(data))
    } else {
       dispatch(addSOS(data))
    }
  }
 
  return (
    <Modal isOpen={show} toggle={onCloseClick}>
      <ModalHeader toggle={onCloseClick}>Add SOS/Emergency Number</ModalHeader>
      {!isValid && (
        <Alert color="danger" style={{ margin: "13px" }}>
          Enter valid SOS number (Should be 11 digit)
        </Alert>
      )}
      {/* <Alert color="danger" style={{ margin: "13px" }}>
          {sos_number?.statusMessage}
       </Alert> */}
      <ModalBody className="py-3 px-5">
        <Form>
          <FormGroup>
            <Label for="contactNumber">
              SOS / Emergency Number<sup className="text-danger"> *</sup>
            </Label>
            <Input
              id="contactNumber"
              name="contactNumber"
              placeholder=""
              type="number"
              onChange={handleOnChange}
              value={sosnumber}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              onCloseClick()
              setIsValid(false)
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  )
}

AddSosModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
}

export default AddSosModal
