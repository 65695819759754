import React from "react"
import { Col, Input, Row, Form } from "reactstrap"

const SearchForm = props => {
  const {
    setFilterUserEmail,
    filterUserEmail,
    handleSubmit,
    removeValues,
    setFilterName,
    filterName,
    removeValuesName,
    filterEmgerencyno,
    setFilterEmgerencyno,
    filterContactno,
    setFilterContactno,
    filterEmgerencyname,
    setFilterEmgerencyname,
    filterJobrole,
    setFilterJobrole,
    filterAddress,
    setFilterAddress,
    removeValuesEmgerencyno,
    removeValuesContactno,
    removeValuesEmgerencyname,
    removeValuesJobrole,
    removeValuesAddress,
  } = props
  return (
    <Form onSubmit={handleSubmit}>
      <Row className="mb-2">
        <Col md={2}>
          <Input
            type="text"
            className="mb-2"
            placeholder="Search for Name"
            value={filterName || ""}
            onChange={e => {
              setFilterName(e.target.value || undefined)
            }}
          />
          <button
            type="button"
            className="close"
            aria-label="Close"
            style={{ marginTop: "-3px", marginRight: "10px" }}
            onClick={removeValuesName}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Col>
        {/* <Col md={2}>
          <Input
            type="text"
            className="mb-2"
            placeholder="Search for Email"
            value={filterUserEmail || ""}
            onChange={e => {
              setFilterUserEmail(e.target.value || undefined)
            }}
          />
          <button
            type="button"
            className="close"
            aria-label="Close"
            style={{ marginTop: "-3px", marginRight: "10px" }}
            onClick={removeValues}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Col>
        <Col md={2}>
          <Input
            type="text"
            className="mb-2"
            placeholder="Search for Address"
            value={filterAddress || ""}
            onChange={e => {
              setFilterAddress(e.target.value || undefined)
            }}
          />
          <button
            type="button"
            className="close"
            aria-label="Close"
            style={{ marginTop: "-3px", marginRight: "10px" }}
            onClick={removeValuesAddress}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Col>


        <Col md={2}>
          <Input
            type="text"
            className="mb-2"
            placeholder="Search for Contact No"
            value={filterContactno || ""}
            onChange={e => {
              setFilterContactno(e.target.value || undefined)
            }}
          />
          <button
            type="button"
            className="close"
            aria-label="Close"
            style={{ marginTop: "-3px", marginRight: "10px" }}
            onClick={removeValuesContactno}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Col> */}

        

        <Col md={2}>
          <Input
            type="text"
            className="mb-2"
            placeholder="Search for User Role"
            value={filterJobrole || ""}
            onChange={e => {
              setFilterJobrole(e.target.value || undefined)
            }}
          />
          <button
            type="button"
            className="close"
            aria-label="Close"
            style={{ marginTop: "-3px", marginRight: "10px" }}
            onClick={removeValuesJobrole}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Col>

        <Col className="justify-content-start">
          <button
            type="submit"
            className="btn btn-primary save-customer me-2"
            onClick={handleSubmit}
          >
            Search
          </button>
        </Col>
      </Row>
    </Form>
  )
}
export default SearchForm

// import { isEmpty } from "lodash"
// import React, { useState } from "react"
// import { Col, Form, Input, Label, Row } from "reactstrap"
// import Select from "react-select"
// const searchForm = props => {
//   const {
//     handleSubmit,
//     filterData,
//     columns,
//     showHide,
//     setshowHide,
//     tableFilter,
//     columnaccessor,
//     setsearchKey,
//     searchKey,
//   } = props

//   const handleChanges = event => {
//     setshowHide(event.target.value)
//   }
  
//   return (
//     <Form onSubmit={handleSubmit}>
//       <Row className="mb-2 mobile_responsive_user">
//         <Col sm={3} className=" justify-content-start">
//           <Select
//             className="mb-2"
//             value={columnaccessor}
//             onChange={value => {
//               tableFilter(value)
//               setsearchKey("")
//             }}
//             options={columns}
//             classNamePrefix="select2-selection"
//           />
//         </Col>
//         <Col sm={3} className=" justify-content-start">
//           <div className="d-flex">
//             <Input
//               type="text"
//               className="mb-2"
//               placeholder={`Search by ${columnaccessor?.Header}`}
//               value={searchKey || ""}
//               onChange={e => {
//                 setsearchKey(e.target.value)
//               }}
//             />

//             {!isEmpty(filterData.Name) && (
//               <button
//                 type="button"
//                 className="close"
//                 aria-label="Close"
//                 style={{ marginTop: "-3px", marginRight: "10px" }}
//               >
//                 <span aria-hidden="true">&times;</span>
//               </button>
//             )}
//           </div>
//         </Col>

//         <Col className=" justify-content-start d-flex">
//           <div className="text-sm-end">
//             <button
//               type="submit"
//               className="btn btn-primary save-customer"
//               onClick={handleSubmit}
//             >
//               Search
//             </button>
//           </div>
//         </Col>
//       </Row>
//     </Form>
//   )
// }
// export default searchForm
