import React from "react"
import { Col, Input, Row, Form } from "reactstrap"

const SearchForm = props => {
  const {
    setFilterUserEmail,
    filterUserEmail,
    handleSubmit,
    removeValues,
    setFilterName,
    filterName,
    removeValuesName,
    setFilterUserType,
    filterUserType,
    removeValuesUserType,
    handleOrSiteClicks,
  } = props
  return (
    <div className="d-flex">
      <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <Row className="mb-2">
          <Col className=" justify-content-start">
            <Input
              type="text"
              className="mb-2"
              placeholder="Search for Name"
              value={filterName || ""}
              onChange={e => {
                setFilterName(e.target.value || undefined)
              }}
            />
            <button
              type="button"
              className="close"
              aria-label="Close"
              style={{ marginTop: "-3px", marginRight: "10px" }}
              onClick={removeValuesName}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Col>
          <Col className=" justify-content-start">
            <Input
              type="text"
              className="mb-2"
              placeholder="Search for Email"
              value={filterUserEmail || ""}
              onChange={e => {
                setFilterUserEmail(e.target.value || undefined)
              }}
            />
            <button
              type="button"
              className="close"
              aria-label="Close"
              style={{ marginTop: "-3px", marginRight: "10px" }}
              onClick={removeValues}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Col>
          <Col className=" justify-content-start">
            <Input
              type="text"
              className="mb-2"
              placeholder="Search for User Type"
              value={filterUserType || ""}
              onChange={e => {
                setFilterUserType(e.target.value || undefined)
              }}
            />
            <button
              type="button"
              className="close"
              aria-label="Close"
              style={{ marginTop: "-3px", marginRight: "10px" }}
              onClick={removeValuesUserType}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Col>
          <Col className="justify-content-start">
            <button
              type="submit"
              className="btn btn-primary save-customer me-2"
              onClick={handleSubmit}
            >
              Search
            </button>
          </Col>
        </Row>
      </Form>
      <div style={{     position: 'absolute', right: '1%' }}>
        <button
          type="submit"
          className="btn btn-primary save-customer me-2"
          onClick={handleOrSiteClicks}
        >
          Add New Users
        </button>
      </div>
    </div>
  )
}
export default SearchForm
