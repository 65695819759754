import { takeEvery, put, call, patch } from "redux-saga/effects"
import toastr from "toastr"
import { togglePageLoader } from "store/actions"
// Chat Redux States
import {
  POST_ADD_NOTIFICATION,
} from "./actionTypes"
import { addNotifySuccess, addNotifyFail } from "./actions"

//Include Both Helper File with needed methods
import { addnotifys } from "../../helpers/site_backend_helper"

function* onAddNotify({ message }) {
  try {
    yield put(togglePageLoader(true))
    const response = yield call(addnotifys, message)
    if (response.statusMessage == 'Unable to sent mail. Please contact admin.') {
      toastr.error(response.statusMessage)
      yield put(togglePageLoader(false))
    } else {
      yield put(addNotifySuccess({ message, ...response }))
      toastr.success("Push Notification send Successfully")
      yield put(togglePageLoader(false))
    }
    setTimeout(() => toastr.clear(), 3000)
  } catch (error) {
    toastr.error(error)
    setTimeout(() => toastr.clear(), 3000)
    yield put(addNotifyFail(error))
  }
}

function* siteSaga() {
  yield takeEvery(POST_ADD_NOTIFICATION, onAddNotify)
}

export default siteSaga
