import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import { postSocialLogin } from "../../../helpers/fakebackend_helper"
import { postLogin } from "helpers/auth_backend_helper"
import { togglePageLoader } from "store/actions"

const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history } }) {
  try {
    yield put(togglePageLoader(true))
    const response = yield call(postLogin, {
      username: user.email,
      password: user.password,
    })
    if (response.user) {
      localStorage.setItem("authUser", JSON.stringify(response))

      yield put(loginSuccess(response))
      localStorage.setItem(
        "authTokenDisplay",
        response.user.userTypes?.[0]?.type_Id
      )
      yield put(togglePageLoader(false))
      history.push("/dashboard")
    } else {
      yield put(apiError(response))
      yield put(togglePageLoader(false))
    }
  } catch (error) {
    yield put(togglePageLoader(false))
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")
    localStorage.removeItem("authTokenDisplay")
    // localStorage.removeItem("firebase_message")
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    yield put(loginSuccess(null))
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      // const fireBaseBackend = getFirebaseBackend()
      const response = yield call(fireBaseBackend.socialLoginUser, data, type)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else {
      const response = yield call(postSocialLogin, data)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    // history.push("/dashboard");
    history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
