import React, { useEffect, useState, Fragment } from "react"
import { Redirect } from "react-router"

export default function Admin({ children }) {
  const [data, setData] = useState({
    isAdmin: false,
    isLoading: true,
  })

  const init = () => {
    const isAdmin = JSON.parse(localStorage.getItem("authTokenDisplay") === "1")
    setData({ ...data, isAdmin, isLoading: false })
  }

  useEffect(() => {
    init()
  }, [data.isAdmin])

  if (data.isLoading) {
    return (
      <Fragment>
        <p>isLoading...</p>
      </Fragment>
    )
  }

  if (!data.isAdmin) {
    return <Redirect to="/dashboard" />
  }

  return <Fragment>{children}</Fragment>
}
