import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/profile/UserProfile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ResetPwd from "../pages/Authentication/ResetPassword"
// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Pages404 from "../pages/Utility/pages-404"

//Ui
import UiAlert from "../pages/Ui/UiAlert"
import UiButtons from "../pages/Ui/UiButtons"
import UiCards from "../pages/Ui/UiCards"
import UiCarousel from "../pages/Ui/UiCarousel"
import UiColors from "../pages/Ui/UiColors"
import UiDropdown from "../pages/Ui/UiDropdown"
import UiGeneral from "../pages/Ui/UiGeneral"
import UiGrid from "../pages/Ui/UiGrid"
import UiImages from "../pages/Ui/UiImages"
import UiLightbox from "../pages/Ui/UiLightbox"
import UiModal from "../pages/Ui/UiModal"
import UiProgressbar from "../pages/Ui/UiProgressbar"
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions"
import UiTypography from "../pages/Ui/UiTypography"
import UiVideo from "../pages/Ui/UiVideo"
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout"
import UiRating from "../pages/Ui/UiRating"
import UiRangeSlider from "../pages/Ui/UiRangeSlider"
import UiNotifications from "../pages/Ui/ui-notifications"
import UiOffCanvas from "pages/Ui/UiOffCanvas"
import UiBreadcrumb from "../pages/Ui/UiBreadcrumb"
import UiPlaceholders from "../pages/Ui/UiPlaceholders"
import UiToasts from "../pages/Ui/UiToast"
// Add New Imports
import Profile from "../pages/profile/index"
import Users from "../pages/users/users"
import Site from "../pages/sites/index"
import CheckinOut from "../pages/clockInOut/index"
import Attachment from "../pages/Attachments/index"
import PushNotificationConfigure from "pages/Notification"
import Admin from "layouts/Admin"
import Worker from "layouts/Worker"
const Adminprofile = () => {
  return (
    <Admin>
      <Profile />
    </Admin>
  )
}
const Adminusers = () => {
  return (
    <Admin>
      <Users />
    </Admin>
  )
}
const Adminclock = () => {
  return (
    <Admin>
      <CheckinOut />
    </Admin>
  )
}
const AdminPush = () => {
  return (
    <Admin>
      <PushNotificationConfigure />
    </Admin>
  )
}
const Workerattch = () => {
  return (
    <Worker>
      <Attachment />
    </Worker>
  )
}
const Workeruserprofile = () => {
  return (
    <Worker>
      <UserProfile />
    </Worker>
  )
}
const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  //Add New Routes.
  { path: "/profile", component: Adminprofile },
  { path: "/users", component: Adminusers },
  { path: "/sites", component: Site },
  { path: "/checkinOut", component: Adminclock },
  { path: "/attachment", component: Workerattch },
  { path: "/profiles", component: Workeruserprofile },
  { path: "/user-profile", component: UserProfile },
  { path: "/push-notification", component: AdminPush },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/my-profile", component: UserProfile },
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },

  // ui modules
  { path: "/ui-alerts", component: UiAlert },
  { path: "/ui-buttons", component: UiButtons },
  { path: "/ui-cards", component: UiCards },
  { path: "/ui-carousel", component: UiCarousel },
  { path: "/ui-colors", component: UiColors },
  { path: "/ui-dropdowns", component: UiDropdown },
  { path: "/ui-general", component: UiGeneral },
  { path: "/ui-grid", component: UiGrid },
  { path: "/ui-images", component: UiImages },
  { path: "/ui-lightbox", component: UiLightbox },
  { path: "/ui-modals", component: UiModal },
  { path: "/ui-progressbars", component: UiProgressbar },
  { path: "/ui-tabs-accordions", component: UiTabsAccordions },
  { path: "/ui-typography", component: UiTypography },
  { path: "/ui-video", component: UiVideo },
  { path: "/ui-session-timeout", component: UiSessionTimeout },
  { path: "/ui-rating", component: UiRating },
  { path: "/ui-rangeslider", component: UiRangeSlider },
  { path: "/ui-notifications", component: UiNotifications },
  { path: "/ui-offcanvas", component: UiOffCanvas },
  { path: "/ui-breadcrumb", component: UiBreadcrumb },
  { path: "/ui-placeholders", component: UiPlaceholders },
  { path: "/ui-toasts", component: UiToasts },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/reset-password", component: ResetPwd },
  { path: "/pages-404", component: Pages404 },
]

export { authProtectedRoutes, publicRoutes }
