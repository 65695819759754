import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Breadcrumbs from "components/Common/Breadcrumb"
import Select from "react-select"
import {
  Card,
  CardBody,
  Col,
  Label,
  Row,
  FormFeedback,
  Input,
  Form,
} from "reactstrap"
import {
  ChangeHeading,
  getRoles as onGetRoles,
  getTypes as onGetTypes,
  getUser as onGetUser,
  addNotify as onAddNotify,
} from "store/actions"
import { useFormik } from "formik"
import * as Yup from "yup"
import Dropzone from "react-dropzone"

const PushNotificationConfigure = () => {
  document.title = "Push Notification | Premier Plastering"
  const dispatch = useDispatch()

  const [filterUserData, setfilterUserData] = useState([])
  const [selectedWorkerId, setselectedWorkerId] = useState()
  const params = {
    pagenumber: 1,
    pagesize: 400,
  }
  useEffect(() => {
    dispatch(onGetRoles())
    dispatch(onGetUser(params))
    dispatch(onGetTypes())
  }, [dispatch])

  const { rolesType, users } = useSelector(state => ({
    rolesType: state.users?.roles.roles,
    users: state.users?.users,
  }))

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  useEffect(() => {
    dispatch(ChangeHeading("Push Notification"))
  }, [])
  const [selectedFiles, setSelectedFiles] = useState([])
  const [selectedobject, setselectedobject] = useState({})
  const [selectedWorkerIduser, setselectedWorkerIdUser] = useState({})
  const [alls, setAlls] = useState([])

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setSelectedFiles(files)
  }
  const handleFileRemove = index => {
    setSelectedFiles(selectedFiles?.filter((_, i) => i !== index))
  }
  // filter UserData
  useEffect(() => {
    const filterValue = users?.filter(i => {
      return selectedWorkerId?.includes(i.userRoles?.[0]?.role_Id)
    })
    setfilterUserData([{ name: "All", username: "all" }, ...filterValue])
    if (filterValue?.length == 0) {
      setfilterUserData([{ name: "All", username: "all" }, ...users])
    }
  }, [selectedWorkerId])

  // initial userdata loaded
  useEffect(() => {
    setfilterUserData([{ name: "All", username: "all" }, ...users])
  }, [users])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      content: "" || "",
      job_role: (setselectedobject && setselectedobject?.job_role) || null,
      username:
        (setselectedWorkerIdUser && setselectedWorkerIdUser?.username) || null,
    },
    validationSchema: Yup.object({
      content: Yup.string().required("Please Enter Content"),
      job_role: Yup.array().required("Please Select Job Role").nullable(),
      username: Yup.array().required("Please Select Username").nullable(),
    }),
    onSubmit: values => {
      const msg = JSON.parse(localStorage.getItem("firebase_message"))
      const max = msg?.reduce(function (prev, current) {
        return prev.id > current.id ? prev : current
      })
      var datas = new FormData()
      if (selectedFiles.length > 0) {
        selectedFiles.map(selectedFile => {
          datas.append("Attachments", selectedFile)
        })
      } else {
        datas.append("Attachments", null)
      }
      datas.append("Subject", values.content)
      if (msg?.id == max?.id) {
        datas.append("Title", msg?.title)
        datas.append("Body", values.content)
      } else {
        datas.append("Title", "msg.title")
        datas.append("Body", values.content)
      }
      if (values?.job_role.length > 0) {
        values?.job_role.map(valuess => {
          datas.append("UserIds", valuess?.value)
        })
      }
      if (alls.length > 0) {
        alls?.map(ids => {
          datas.append("UserIds", ids.id)
        })
      } else if (values?.username.length > 0) {
        values?.username.map(valuess => {
          datas.append("UserIds", valuess?.id)
        })
      }

      dispatch(onAddNotify(datas))
      setTimeout(function () {
        validation.resetForm()
        setSelectedFiles([])
      }, 3000)
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Dashboard" breadcrumbItem={"Push Notification"} />
          <Row>
            <Form
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <Col xs="12">
                <Card>
                  <CardBody>
                    <Col lg="6">
                      <div className="mb-3">
                        <Label>
                          Workers Type <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Select
                          isMulti
                          name="job_role"
                          options={rolesType?.map(el => ({
                            label: el.name,
                            value: el.id,
                          }))}
                          value={validation.values.job_role}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={value => {
                            validation.setFieldValue(
                              "job_role",
                              value?.length > 0 ? value : ""
                            ),
                              validation.setFieldValue("username", ""),
                            setselectedWorkerId(value.map(i => i?.value))
                          }}
                        />

                        {validation.touched.job_role &&
                        validation.errors.job_role ? (
                          <div className="text-danger change_danger_text">
                            {validation.errors.job_role}
                          </div>
                        ) : null}
                      </div>
                    </Col>

                    <Col lg="6">
                      <div className="mb-3">
                        <Label>
                          Who to <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Select
                          isMulti
                          name="username"
                          options={filterUserData?.map(el => ({
                            ...el,
                            label: el.name,
                            value: el.id,
                          }))}
                          value={validation.values.username || ""}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          invalid={
                            validation.touched.username &&
                            validation.errors.username
                              ? true
                              : false
                          }
                          onChange={value => {
                            let allSelect = value?.filter(
                              v => v.username == "all"
                            )

                            setAlls([])
                            if (allSelect.length > 0) {
                              setAlls(
                                filterUserData.filter(i =>
                                  i.username != "all" ? i.id : false
                                )
                              )
                            }
                            validation.setFieldValue(
                              "username",
                              value?.length > 0 ? value : ""
                            )
                          }}
                        />
                        {validation.touched.username &&
                        validation.errors.username ? (
                          <div className="text-danger change_danger_text">
                            {validation.errors.username}
                          </div>
                        ) : null}
                      </div>
                    </Col>

                    <Col lg="6">
                      <div className="mb-3">
                        <Label className="form-label">
                          Content <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          name="content"
                          type="textarea"
                          rows="3"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.content || ""}
                          invalid={
                            validation.touched.content &&
                            validation.errors.content
                              ? true
                              : false
                          }
                        />
                        {validation.touched.content &&
                        validation.errors.content ? (
                          <FormFeedback type="invalid">
                            {validation.errors.content}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg="6">
                      <Label>Attachment</Label>
                      <Form>
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedFiles(acceptedFiles)
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews my-3"
                          id="file-previews"
                        >
                          {selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col>
                                      <p className="mb-0 text-muted font-weight-bold">
                                        {f.name}
                                      </p>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                      <button
                                        type="button"
                                        className="btn-close"
                                        aria-label="Close"
                                        style={{
                                          position: "absolute",
                                          right: "10px",
                                          top: 0,
                                          cursor: "pointer",
                                        }}
                                        onClick={() => handleFileRemove(i)}
                                      ></button>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                        </div>
                      </Form>
                    </Col>
                    <Row>
                      <Col lg="6">
                        <div className="text-start">
                          <button
                            type="submit"
                            className="btn btn-success save-user"
                          >
                            Send Push Notifications
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Form>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PushNotificationConfigure
