import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
import { ChangeHeading } from "../../store/dashboard/actions"
import { getClock as onGetClock } from "store/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
import { BillingName, PaymentStatus, LogTime, LocationName } from "./clockCols"
//redux
import { useSelector, useDispatch } from "react-redux"
import EcommerceOrdersModal from "../Ecommerce/EcommerceOrders/EcommerceOrdersModal"
import { Col, Row, Card, CardBody } from "reactstrap"
import moment from "moment"
import SearchForm from "./searchForm"

function CLOCKINOUT() {
  //meta title
  document.title = "CHECK IN/OUT | Premier Plastering"
  const [modal1, setModal1] = useState(false)
  const toggleViewModal = () => setModal1(!modal1)
  const [datas, setDatas] = useState([])
  const [pageSize, setpageSize] = useState(10)
  const [pageindex, setpageindex] = useState(0)
  const [filterCheckIn, setFilterCheckIn] = useState()
  const [filterCheckOut, setFilterCheckOut] = useState()
  const [filterWorkerSiteName, setFilterWorkerSiteName] = useState()
  const [sort, setSort] = useState({ direction: "asc", accessor: "Name" })

  const dispatch = useDispatch()
  const { clocks, totalcount } = useSelector(state => ({
    clocks: state?.sites?.clock,
    totalcount: state?.sites?.count,
  }))

  const params = {
    pagenumber: 1,
    pagesize: pageSize,
    sortKey: sort.accessor,
    sortValue: sort.direction,
  }

  var changeClockmethod = clocks?.map(el => ({
    id: el?.workersLog?.id,  
    clock_In:
      el?.workersLog?.clock_In && `${moment(el?.workersLog?.clock_In).format("MMMM Do YYYY, h:mm:ss a")}`,

    clock_Out:
      el?.workersLog?.clock_Out &&
      `${moment(el?.workersLog?.clock_Out).format("MMMM Do YYYY, h:mm:ss a")}`,

    latitude: el?.workersLog?.latitude,
    longitude: el?.workersLog?.longitude,
    name: el?.sites?.[0]?.name,
    WorkerName: el?.users?.[0]?.name,
    checkout_latitude: el?.workersLog?.checkout_latitude,
    checkout_longitude: el?.workersLog?.checkout_longitude,
  }))

  useEffect(() => {
    setDatas(changeClockmethod)
  }, [clocks])

  useEffect(() => {
    dispatch(onGetClock(params))
  }, [dispatch])

  useEffect(() => {
    dispatch(ChangeHeading("CHECK IN/OUT"))
  }, [])

  const handlePageValue = value => {
    if (!value.noChange) setpageindex(value.pageNumber)
    setpageSize(value.pageSize)
    dispatch(
      onGetClock({
        ...value,
        pageNumber: value.pageNumber + 1,
      })
    )
  }
  
  const columns = useMemo(
    () => [
      {
        Header: "Worker Name",
        accessor: "WorkerName",
        disableFilters: true,
        sortName: "WorkerName",
        sortDirection: sort.accessor === "WorkerName" ? sort.direction : "none",
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Check In",
        accessor: "clock_In",
        disableFilters: true,
        sortName: "clock_In",
        sortDirection: sort.accessor === "clock_In" ? sort.direction : "none",
        Cell: cellProps => {
          return <LocationName {...cellProps} />
        },
      },
      {
        Header: "Check Out",
        accessor: "clock_Out",
        sortName: "clock_Out",
        sortDirection: sort.accessor === "clock_Out" ? sort.direction : "none",
        disableFilters: true,
        Cell: cellProps => {
          return <LocationName {...cellProps} />
        },
      }
    ],
    [sort]
  )

  
  // const removeValuesrCheckIn = () => {
  //   setFilterCheckIn("")
  //   if (filterCheckIn?.length > 0) {
  //     dispatch(
  //       onGetClock({
  //         pageNumber: 1,
  //         pageSize: pageSize,
  //         siteName: filterSiteName || "",
  //         managername: filterWorkerSiteName || "",
  //         checkin: "",
  //         checkout: filterCheckOut || "",
  //       })
  //     )
  //   } else {
  //     dispatch(
  //       onGetClock({
  //         pageNumber: 1,
  //         pageSize: pageSize,
  //         siteName: "",
  //         managername: "",
  //         checkin: "",
  //         checkout: "",
  //       })
  //     )
  //   }
  // }
  // const removeValuesrCheckOut = () => {
  //   setFilterCheckOut("")
  //   if (filterCheckOut?.length > 0) {
  //     dispatch(
  //       onGetClock({
  //         pageNumber: 1,
  //         pageSize: pageSize,
  //         siteName: filterSiteName || "",
  //         managername: filterWorkerSiteName || "",
  //         checkin: filterCheckIn || "",
  //         checkout: "",
  //       })
  //     )
  //   } else {
  //     dispatch(
  //       onGetClock({
  //         pageNumber: 1,
  //         pageSize: pageSize,
  //         siteName: "",
  //         managername: "",
  //         checkin: "",
  //         checkout: "",
  //       })
  //     )
  //   }
  // }
  const removeValuesrWorkerSiteName = () => {
    setFilterWorkerSiteName("")
    if (filterWorkerSiteName?.length > 0) {
      dispatch(
        onGetClock({
          pageNumber: 1,
          pageSize: pageSize,
          siteName: "",
          // managername: "",
          WorkerName: "",
          checkin: filterCheckIn || "",
          checkout: filterCheckOut || "",
        })
      )
    } else {
      dispatch(
        onGetClock({
          pageNumber: 1,
          pageSize: pageSize,
          siteName: "",
          // managername: "",
          WorkerName: "",
          checkin: "",
          checkout: "",
        })
      )
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(
      onGetClock({
        pageNumber: 1,
        pageSize: pageSize,
        siteName: "",
        // managername: filterWorkerSiteName || "",
        WorkerName: filterWorkerSiteName || "",
        checkin: filterCheckIn || "",
        checkout: filterCheckOut || "",
      })
    )
  }
  const columnHeaderClick = (column, pagesize, pageIndex) => {
    switch (column.sortDirection) {
      case "none":
        setSort({ direction: "asc", accessor: column.sortName })
        handlePageValue({
          pageSize: pagesize,
          pageNumber: pageIndex,
          sortKey: column.sortName,
          sortValue: "asc",
        })
        break
      case "asc":
        setSort({ direction: "desc", accessor: column.sortName })
        handlePageValue({
          pageSize: pagesize,
          pageNumber: pageIndex,

          sortKey: column.sortName,
          sortValue: "desc",
        })
        break
      case "desc":
        setSort({ direction: "asc", accessor: column.sortName })
        handlePageValue({
          pageSize: pagesize,
          pageNumber: pageIndex,
          sortKey: column.sortName,
          sortValue: "asc",
        })
        break
    }
  }

  return (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Dashboard" breadcrumbItem={"Check In/Out"} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <SearchForm
                    filterCheckIn={filterCheckIn}
                    setFilterCheckIn={setFilterCheckIn}
                    filterCheckOut={filterCheckOut}
                    setFilterCheckOut={setFilterCheckOut}
                    filterWorkerSiteName={filterWorkerSiteName}
                    setFilterWorkerSiteName={setFilterWorkerSiteName}
                    // removeValuesrCheckIn={removeValuesrCheckIn}
                    // removeValuesrCheckOut={removeValuesrCheckOut}
                    removeValuesrWorkerSiteName={removeValuesrWorkerSiteName}
                    handleSubmit={handleSubmit}
                  />

                  <TableContainer
                    keyField="id"
                    handlePageValue={handlePageValue}
                    columns={columns}
                    totalCount={totalcount || 0}
                    data={datas || []}
                    isGlobalFilter={true}
                    customPageSize={10}
                    pageindex={pageindex}
                    setpageindex={setpageindex}
                    onHeaderClick={columnHeaderClick}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
CLOCKINOUT.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default CLOCKINOUT
