import { PAGE_LOADER } from "./actionTypes"

const initialState = {
  pageLoader: false,
}

const globalLists = (state = initialState, action) => {
  switch (action.type) {

    case PAGE_LOADER:
      state = { ...state, pageLoader: action.payload }
      break
      
    default:
      state = { ...state }
      break
  }
  return state
}

export default globalLists
