import {
  GET_USER,
  GET_USER_FAIL,
  GET_USER_SUCCESS,
  POST_ADD_USER,
  POST_ADD_USER_SUCCESS,
  POST_ADD_USER_FAIL,
  POST_UPDATE_USER,
  POST_UPDATE_USER_SUCCESS,
  POST_UPDATE_USER_FAIL,
  POST_DELETE_USER,
  POST_DELETE_USER_SUCCESS,
  POST_DELETE_USER_FAIL,
  GET_ROLES,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAIL,
  GET_TYPES,
  GET_TYPES_SUCCESS,
  GET_TYPES_FAIL,
  POST_ATTACHEMENT_USER,
  POST_ATTACHEMENT_USER_SUCCESS,
  POST_ATTACHEMENT_USER_FAIL,
  POST_FORGET_USER,
  POST_FORGET_USER_SUCCESS,
  POST_FORGET_USER_FAIL,
  POST_RESET_USER_FAIL,
  POST_RESET_USER_SUCCESS,
  POST_RESET_USER,
  POST_TOKEN_USER_FAIL,
  POST_TOKEN_USER_SUCCESS,
  POST_TOKEN_USER,
  POST_DELETE_ATTC_USER,
  POST_DELETE_ATTC_USER_SUCCESS,
  POST_DELETE_ATTC_USER_FAIL,
  POST_UPDATE_USERS,
  POST_UPDATE_USERS_SUCCESS,
  POST_UPDATE_USERS_FAIL,
} from "./actionTypes"

// Get user
export const getUser = data => ({
  type: GET_USER,
  payload: data
})

export const getUserSuccess = chats => ({
  type: GET_USER_SUCCESS,
  payload: chats,
})

export const getUserFail = error => ({
  type: GET_USER_FAIL,
  payload: error,
})
// Add user
export const addUser = (message, callback) => ({
  type: POST_ADD_USER,
  message,
  callback,
})

export const addUserSuccesss = message => ({
  type: POST_ADD_USER_SUCCESS,
  payload: message,
})

export const addUserFails = error => ({
  type: POST_ADD_USER_FAIL,
  payload: error,
})
// update
export const upUser = (id, callback) => ({
  type: POST_UPDATE_USER,
  id,
  callback
})

export const upUserSuccesss = message => ({
  type: POST_UPDATE_USER_SUCCESS,
  payload: message,
})

export const upUserFails = error => ({
  type: POST_UPDATE_USER_FAIL,
  payload: error,
})
// 2nd check updates users
export const upUsers = (id, callback) => ({
  type: POST_UPDATE_USERS,
  id,
  callback,
})

export const upUsersSuccesss = message => ({
  type: POST_UPDATE_USERS_SUCCESS,
  payload: message,
})

export const upUsersFails = error => ({
  type: POST_UPDATE_USERS_FAIL,
  payload: error,
})
//delete
export const delUser = id => ({
  type: POST_DELETE_USER,
  id,
})

export const delUserSuccesss = message => ({
  type: POST_DELETE_USER_SUCCESS,
  payload: message,
})

export const delUserFails = error => ({
  type: POST_DELETE_USER_FAIL,
  payload: error,
})

// RolesList

export const getRoles = () => ({
  type: GET_ROLES,
})

export const getRolesSuccess = chats => ({
  type: GET_ROLES_SUCCESS,
  payload: chats,
})

export const getRolesFail = error => ({
  type: GET_ROLES_FAIL,
  payload: error,
})

//TypeList

export const getTypes = () => ({
  type: GET_TYPES,
})

export const getTypesSuccess = chats => ({
  type: GET_TYPES_SUCCESS,
  payload: chats,
})

export const getTypesFail = error => ({
  type: GET_TYPES_FAIL,
  payload: error,
})
// ATTACHEMENTS USER
export const attachementUser = message => ({
  type: POST_ATTACHEMENT_USER,
  message,
})

export const attachementUserSuccesss = message => ({
  type: POST_ATTACHEMENT_USER_SUCCESS,
  payload: message,
})

export const attachementUserFails = error => ({
  type: POST_ATTACHEMENT_USER_FAIL,
  payload: error,
})

export const forgetUser = username => ({
  type: POST_FORGET_USER,
  username,
})

export const forgetUserSuccesss = message => ({
  type: POST_FORGET_USER_SUCCESS,
  payload: message,
})

export const forgetUserFails = error => ({
  type: POST_FORGET_USER_FAIL,
  payload: error,
})
//reset password
export const resetUser = (data, history) => ({
  type: POST_RESET_USER,
  payload: { data, history },
})

export const resetUserSuccesss = data => ({
  type: POST_RESET_USER_SUCCESS,
  payload: data,
})

export const resetUserFails = error => ({
  type: POST_RESET_USER_FAIL,
  payload: error,
})

//token password
export const tokenUser = id => ({
  type: POST_TOKEN_USER,
  id: id,
})

export const tokenUserSuccesss = message => ({
  type: POST_TOKEN_USER_SUCCESS,
  payload: message,
})

export const tokenUserFails = error => ({
  type: POST_TOKEN_USER_FAIL,
  payload: error,
})

export const delUserAttc = id => ({
  type: POST_DELETE_ATTC_USER,
  id,
})

export const delUserAttcSuccesss = id => ({
  type: POST_DELETE_ATTC_USER_SUCCESS,
  payload: id,
})
export const delUserAttcFails = error => ({
  type: POST_DELETE_ATTC_USER_FAIL,
  payload: error,
})
