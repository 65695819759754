import React, { useEffect, useState, Fragment } from "react"
import { Redirect } from "react-router"

export default function Admin({ children }) {
  const [data, setData] = useState({
    isWorker: false,
    isLoading: true,
  })

  const init = () => {
    const isWorker = JSON.parse(localStorage.getItem("authTokenDisplay") === "2")
    setData({ ...data, isWorker, isLoading: false })
  }

  useEffect(() => {
    init()
  }, [data.isWorker])

  if (data.isLoading) {
    return (
      <Fragment>
        <p>isLoading...</p>
      </Fragment>
    )
  }

  if (!data.isWorker) {
    return <Redirect to="/dashboard" />
  }

  return <Fragment>{children}</Fragment>
}
