/* Users */
export const GET_USER = "GET_USER"
export const GET_USER_SUCCESS = "GET_USER_SUCCESS"
export const GET_USER_FAIL = "GET_USER_FAIL"

export const POST_ADD_USER = "POST_ADD_USER"
export const POST_ADD_USER_SUCCESS = "POST_ADD_USER_SUCCESS"
export const POST_ADD_USER_FAIL = "POST_ADD_USER_FAIL"

export const POST_UPDATE_USER = "POST_UPDATE_USER"
export const POST_UPDATE_USER_SUCCESS = "POST_UPDATE_USER_SUCCESS"
export const POST_UPDATE_USER_FAIL = "POST_UPDATE_USER_FAIL"

export const POST_UPDATE_USERS = "POST_UPDATE_USERS"
export const POST_UPDATE_USERS_SUCCESS = "POST_UPDATE_USERS_SUCCESS"
export const POST_UPDATE_USERS_FAIL = "POST_UPDATE_USERS_FAIL"

export const POST_DELETE_USER = "POST_DELETE_USER"
export const POST_DELETE_USER_SUCCESS = "POST_DELETE_USER_SUCCESS"
export const POST_DELETE_USER_FAIL = "POST_DELETE_USER_FAIL"

export const GET_ROLES = "GET_ROLES"
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS"
export const GET_ROLES_FAIL = "GET_ROLES_FAIL"

export const GET_TYPES = "GET_TYPES"
export const GET_TYPES_SUCCESS = "GET_TYPES_SUCCESS"
export const GET_TYPES_FAIL = "GET_TYPES_FAIL"

export const POST_ATTACHEMENT_USER = "POST_ATTACHEMENT_USER"
export const POST_ATTACHEMENT_USER_SUCCESS = "POST_ATTACHEMENT_USER_SUCCESS"
export const POST_ATTACHEMENT_USER_FAIL = "POST_ATTACHEMENT_USER_FAIL"

export const POST_FORGET_USER = "POST_FORGET_USER"
export const POST_FORGET_USER_SUCCESS = "POST_FORGET_USER_SUCCESS"
export const POST_FORGET_USER_FAIL = "POST_FORGET_USER_FAIL"

export const POST_RESET_USER = "POST_RESET_USER"
export const POST_RESET_USER_SUCCESS = "POST_RESET_USER_SUCCESS"
export const POST_RESET_USER_FAIL = "POST_RESET_USER_FAIL"

export const POST_TOKEN_USER = "POST_TOKEN_USER"
export const POST_TOKEN_USER_SUCCESS = "POST_TOKEN_USER_SUCCESS"
export const POST_TOKEN_USER_FAIL = "POST_TOKEN_USER_FAIL"

export const POST_DELETE_ATTC_USER = "POST_DELETE_ATTC_USER"
export const POST_DELETE_ATTC_USER_SUCCESS = "POST_DELETE_ATTC_USER_SUCCESS"
export const POST_DELETE_ATTC_USER_FAIL = "POST_DELETE_ATTC_USER_FAIL"