/* SITES */
export const GET_SITES = "GET_SITES"
export const GET_SITES_SUCCESS = "GET_SITES_SUCCESS"
export const GET_SITES_FAIL = "GET_SITES_FAIL"

export const POST_ADD_SITE = "POST_ADD_SITE"
export const POST_ADD_SITE_SUCCESS = "POST_ADD_SITE_SUCCESS"
export const POST_ADD_SITE_FAIL = "POST_ADD_SITE_FAIL"

export const POST_UPDATE_SITE = "POST_UPDATE_SITE"
export const POST_UPDATE_SITE_SUCCESS = "POST_UPDATE_SITE_SUCCESS"
export const POST_UPDATE_SITE_FAIL = "POST_UPDATE_SITE_FAIL"

export const POST_DELETE_SITE = "POST_DELETE_SITE"
export const POST_DELETE_SITE_SUCCESS = "POST_DELETE_SITE_SUCCESS"
export const POST_DELETE_SITE_FAIL = "POST_DELETE_SITE_FAIL"

export const POST_ADD_CLOCK = "POST_ADD_CLOCK"
export const POST_ADD_CLOCK_SUCCESS = "POST_ADD_CLOCK_SUCCESS"
export const POST_ADD_CLOCK_FAIL = "POST_ADD_CLOCK_FAIL"

export const POST_UP_CLOCK = "POST_UP_CLOCK"
export const POST_UP_CLOCK_SUCCESS = "POST_UP_CLOCK_SUCCESS"
export const POST_UP_CLOCK_FAIL = "POST_UP_CLOCK_FAIL"

export const GET_CLOCK = "GET_CLOCK"
export const GET_CLOCK_SUCCESS = "GET_CLOCK_SUCCESS"
export const GET_CLOCK_FAIL = "GET_CLOCK_FAIL"

export const POST_DELETE_ATTC = "POST_DELETE_ATTC"
export const POST_DELETE_ATTC_SUCCESS = "POST_DELETE_ATTC_SUCCESS"
export const POST_DELETE_ATTC_FAIL = "POST_DELETE_ATTC_FAIL"

export const GET_CHECK_IN = "GET_CHECK_IN"
export const GET_CHECK_IN_SUCCESS = "GET_CHECK_IN_SUCCESS"
export const GET_CHECK_IN_FAIL = "GET_CHECK_IN_FAIL"