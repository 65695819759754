import {
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  POST_ADD_USER_SUCCESS,
  POST_ADD_USER_FAIL,
  POST_UPDATE_USER_SUCCESS,
  POST_UPDATE_USER_FAIL,
  POST_DELETE_USER_SUCCESS,
  POST_DELETE_USER_FAIL,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAIL,
  GET_TYPES_SUCCESS,
  GET_TYPES_FAIL,
  POST_ATTACHEMENT_USER_SUCCESS,
  POST_ATTACHEMENT_USER_FAIL,
  POST_FORGET_USER_SUCCESS,
  POST_FORGET_USER_FAIL,
  POST_RESET_USER_SUCCESS,
  POST_RESET_USER_FAIL,
  POST_TOKEN_USER_SUCCESS,
  POST_TOKEN_USER_FAIL,
  POST_DELETE_ATTC_USER_SUCCESS,
  POST_DELETE_ATTC_USER_FAIL,
  POST_UPDATE_USERS_SUCCESS,
  POST_UPDATE_USERS_FAIL,
} from "./actionTypes"

import { formatojson } from "../../helpers/util"

const INIT_STATE = {
  users: [],
  forget: [],
  reset: [],
  token: [],
  roles: [],
  type: [],
  error: "",
  success: [],
  successs: [],
  successadd: [],
  userCount:[]
}

const Calendars = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER_SUCCESS:
      let Getlists = action.payload.users?.filter(v => v)
      // let ids = JSON.parse(localStorage.getItem("authUser"))
      // let Getlists1 = Getlists.filter(item => item.id !== ids.user.id)
      let userCount = action.payload?.rowCount
      return {
        ...state,
        // users: Getlists1,
        users: Getlists,
        success: [],
        successs: [],
        userCount:userCount
      }

    case GET_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case POST_ADD_USER_SUCCESS:
      return {
        ...state,
        successadd: action.payload,
      }

    case POST_ADD_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    // update

    case POST_UPDATE_USER_SUCCESS:
      const updated = state.users.filter(v => v)
      const updatedData = updated.map(item => {
        if (item.id == action.payload.id) {
          let userTypes = [
            formatojson(JSON.parse(action.payload?.userTypes)[0]),
          ]
          let userRoles = [
            formatojson(JSON.parse(action.payload?.userRoles)[0]),
          ]
          return { ...item, ...{ ...action.payload, userTypes, userRoles } }
        }
        return item
      })
      return {
        ...state,
        users: updatedData,
        success: action.payload,
      }

    case POST_UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case POST_UPDATE_USERS_SUCCESS:
      const updated1 = state.users.filter(v => v)
      const updatedData1 = updated1.map(item => {
        if (item.id == action.payload.id) {
          let userTypes = [
            formatojson(JSON.parse(action.payload?.userTypes)[0]),
          ]
          return { ...item, ...{ ...action.payload, userTypes } }
        }
        return item
      })
      return {
        ...state,
        users: updatedData1,
        successs: action.payload,
      }

    case POST_UPDATE_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // Delete

    case POST_DELETE_USER_SUCCESS:
      let deleted = state.users.filter(v => v)
      let deleteFilter = deleted.filter(item => item.id !== action.payload)
      return {
        ...state,
        users: deleteFilter,
      }
    case POST_DELETE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload,
      }

    case GET_ROLES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_TYPES_SUCCESS:
      return {
        ...state,
        type: action.payload,
      }

    case GET_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    // Attachements post method
    case POST_ATTACHEMENT_USER_SUCCESS:
      return {
        type: action.payload,
      }

    case POST_ATTACHEMENT_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    // Forget User
    case POST_FORGET_USER_SUCCESS:
      return {
        ...state,
        forget: action.payload,
      }

    case POST_FORGET_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    //reset Password
    case POST_RESET_USER_SUCCESS:
      return {
        ...state,
        reset: action.payload,
      }

    case POST_RESET_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    //token password
    case POST_TOKEN_USER_SUCCESS:
      return {
        ...state,
        token: action.payload,
      }

    case POST_TOKEN_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    // delete user attchments
    case POST_DELETE_ATTC_USER_SUCCESS:

      let deleteFilters = state.users?.map(item => {
        if (item.id == action.payload.user_Id) {
          delete item.userAttachments
          item.userAttachments = []
          return item
        }
        return item
      })

      return {
        ...state,
        userss: deleteFilters,
      }
    case POST_DELETE_ATTC_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Calendars
