import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"
//i18n
import { withTranslation } from "react-i18next"
// import { requestForToken, onMessageListener } from "Notifi"
import moment from "moment"

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [notification, setNotification] = useState([])
  const [startTime, setStartTime] = useState(null)

  // requestForToken()
  // onMessageListener()
  //   .then(payload => {
  //     const old = JSON.parse(localStorage.getItem("firebase_message"))
  //     const max = old?.reduce(function (prev, current) {
  //       return prev.id > current.id ? prev : current
  //     })
  //     if (old !== null) {
  //       const merge = [
  //         {
  //           title: payload?.notification?.title,
  //           body: payload?.notification?.body,
  //           id: max.id + 1,
  //         },
  //         ...old,
  //       ]
  //       setNotification(merge)
  //       localStorage.setItem(
  //         "firebase_message",
  //         JSON.stringify(merge),
  //         startTime
  //       )
  //     } else {
  //       const notify = [
  //         {
  //           title: payload?.notification?.title,
  //           body: payload?.notification?.body,
  //           time: new Date(),
  //           id: 1,
  //         },
  //       ]
  //       setNotification(notify)
  //       // setStartTime(Date().toLocaleString())
  //       localStorage.setItem(
  //         "firebase_message",
  //         JSON.stringify(notify),
  //         startTime
  //       )
  //     }
  //     // let now = new Date();
  //     setStartTime(Date().toLocaleString())
  //   })
  //   .catch(err => console.log("failed: ", err))
  // const msg = JSON.parse(localStorage.getItem("firebase_message"))
  // const maxx = msg?.reduce(function (prev, current) {
  //   return prev.id > current.id ? prev : current
  // })
  // localStorage.setItem("readmsg", maxx?.id)
  
  // const [ids, setIds] = useState(maxx?.id)
  // useEffect = () => {
  //   setIds()
  // },[ids]
  // const removeDatass = JSON.parse(localStorage.getItem("readmsg"))
  // const removeDatas = () => {
    // localStorage.removeItem("readmsg")
    // setIds("")
  // }
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          {/* <i className="bx bx-bell bx-tada" /> */}
          {/* <span className="badge bg-danger rounded-pill">{removeDatass}</span> */}
          <span className="badge bg-danger rounded-pill"></span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              <div className="col-auto">
                <a href="#" className="small" >
                  View All
                </a>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            <Link to="" className="text-reset notification-item">
              {/* {msg?.map(data => {
                return (
                  <div className="d-flex" key={data?.id}>
                    <div className="avatar-xs me-3">
                      <span className="avatar-title bg-primary rounded-circle font-size-16">
                        <i className="bx bx-badge-check" />
                      </span>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mt-0 mb-1">{data?.title}</h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">{data?.body}</p>
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline" />
                          {moment(data?.time).fromNow(true)}
                        </p>
                      </div>
                    </div>
                  </div>
                )
              })} */}
            </Link>
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 text-center"
              to="#"
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
              <span key="t-view-more">{props.t("View More..")}</span>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any,
}
