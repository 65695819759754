import React, { useState, useEffect } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Form,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"
import { useDispatch } from "react-redux"
import { ChangeHeading } from "../../store/dashboard/actions"
import Dropzone from "react-dropzone"
import * as Yup from "yup"
import { useFormik } from "formik"
import { attachementUser as onAttachementUser } from "store/actions"
const Attachments = () => {
  //meta title
  document.title = "Plastering | Attachments"

  const [selectedFiles, setSelectedFiles] = useState([])
  const usermail = JSON.parse(localStorage.getItem("authUser"))
  const dispatch = useDispatch()
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "" || "",
      notes: "" || "",
      ToEmail: "" || "",
      Attachments: "" || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter your Title"),
    }),
    onSubmit: values => {
      var datas = new FormData()
      selectedFiles.map(selectedFile => {
        datas.append("Attachments", selectedFile)
      })
      datas.append("ToEmail", usermail.user.username)
      datas.append("Title", values.title)
      datas.append("Body", values.notes)
      dispatch(onAttachementUser(datas))
      setTimeout(function () {
        validation.resetForm()
        setSelectedFiles([])
      }, 3000)
    },
  })
  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setSelectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const handleFileRemoves = index => {
    setSelectedFiles(selectedFiles.filter((_, i) => i !== index))
  }
  useEffect(() => {
    dispatch(ChangeHeading("ATTACHMENTS"))
  }, [dispatch])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Dashboard" breadcrumbItem="Attachment" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Col lg={6}>
                      <Row className="mb-3">
                        <div>
                          <Label className="form-label">Title <span style={{ color: 'red' }}>*</span></Label>
                          <Input
                            name="title"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.title || ""}
                            invalid={
                              validation.touched.title &&
                              validation.errors.title
                                ? true
                                : false
                            }
                          />
                          {validation.touched.title &&
                          validation.errors.title ? (
                            <FormFeedback type="invalid">
                              {validation.errors.title}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <div>
                          <Label className="form-label">Notes</Label>
                          <Input
                            name="notes"
                            type="textarea"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.notes || ""}
                            invalid={
                              validation.touched.notes &&
                              validation.errors.notes
                                ? true
                                : false
                            }
                          />
                          {validation.touched.notes &&
                          validation.errors.notes ? (
                            <FormFeedback type="invalid">
                              {validation.errors.notes}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <div>
                          <Input
                            hidden
                            name="ToEmail"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.ToEmail || ""}
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <div>
                          <Label className="form-label">Attachments</Label>
                          <Form>
                            <Dropzone
                              onDrop={acceptedFiles => {
                                handleAcceptedFiles(acceptedFiles)
                              }}
                              // validator={[]}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div className="dropzone">
                                  <div
                                    className="dz-message needsclick mt-2"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <div className="mb-3">
                                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                                    </div>
                                    <h4>Drop files here or click to upload.</h4>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                            <div
                              className="dropzone-previews my-3"
                              id="file-previews"
                            >
                              {selectedFiles.map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col>
                                          <p className="mb-0 text-muted font-weight-bold">
                                            {f.name}
                                          </p>
                                          <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                          </p>
                                          <button
                                            type="button"
                                            className="btn-close"
                                            aria-label="Close"
                                            style={{
                                              position: "absolute",
                                              right: "10px",
                                              top: 0,
                                              cursor: "pointer",
                                            }}
                                            onClick={() => handleFileRemoves(i)}
                                          ></button>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                )
                              })}
                            </div>
                          </Form>
                        </div>
                      </Row>
                    </Col>
                    <div>
                      <button type="submit" className="btn btn-primary w-md">
                        SEND
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Attachments
