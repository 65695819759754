import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"
import DeleteModal from "../../components/Common/DeleteModal"
import { ChangeHeading } from "../../store/dashboard/actions"
import * as moment from "moment"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  getUser as onGetUser,
  upUser as updateUser,
  delUser as deleteUser,
  getRoles as onGetRoles,
  delUserAttc as deleteUserAttc,
} from "store/actions"
//redux
import { useDispatch, useSelector } from "react-redux"

import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import Dropzone from "react-dropzone"
import SearchForm from "./searchForm"
import InputMask from "react-input-mask"

function Profile() {
  //meta title
  document.title = "Profiles | Premier Plastering"

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])
  const [profile, setProfile] = useState({})
  const [order, setOrder] = useState([])
  const [pageSize, setpageSize] = useState(10)
  const [pageindex, setpageindex] = useState(0)
  const [filterUserEmail, setFilterUserEmail] = useState()
  const [filterName, setFilterName] = useState()
  const [filterUserType, setFilterUserType] = useState()
  const [filterEmgerencyno, setFilterEmgerencyno] = useState()
  const [filterContactno, setFilterContactno] = useState()
  const [filterEmgerencyname, setFilterEmgerencyname] = useState()
  const [filterAddress, setFilterAddress] = useState()
  const [filterJobrole, setFilterJobrole] = useState()
  const [sort, setSort] = useState({ direction: "desc", accessor: "Name" })
  // const [expiryDates, setExpiryDates] =useState(null)

  const { users, rolesType, success, totalcount } = useSelector(state => ({
    users: state.users.users,
    rolesType: state.users?.roles?.roles,
    success: state.users.success?.statusMessage,
    totalcount: state?.users?.userCount,
  }))

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setSelectedFiles(files)
  }
  const disablePastDate = () => {
    const today = new Date()
    const dd = String(today.getDate() + 1).padStart(2, "0")
    const mm = String(today.getMonth() + 1).padStart(2, "0") //January is 0!
    const yyyy = today.getFullYear()
    return yyyy + "-" + mm + "-" + dd
  }
  const handleFileRemoves = index => {
    setSelectedFiles(selectedFiles.filter((_, i) => i !== index))
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: profile?.name || "",
      username: profile?.username || "",
      address: profile?.address || "",
      emergency_Contact: profile?.emergency_Contact || "",
      number: profile?.number || "",
      job_role: profile?.userRoles?.[0]?.role_Id || "",
      profile_image: profile?.profile_image,
      date: profile?.dob || "",
      emergency_Name: profile?.emergency_Name,
      is_Deleted: profile?.is_Deleted || true,
      ses_card_qualification:
        profile?.userAttachments?.[0]?.attachments?.fileName,
      comapany_name: profile?.company_Name || "",
      cardNumber: profile?.card_Number || "",
      expiryDate: profile?.expiryDate || "",
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      address: Yup.string().required("Please Enter Address"),
      emergency_Contact: Yup.number()
        .typeError("Must be valid Number")
        .required("Please Enter Emergency Number"),
      number: Yup.number()
        .typeError("Must be valid Number")
        .required("Please Enter Contact Number"),
      username: Yup.string()
        .email("Enter valid Email address")
        .max(255)
        .required("Please Enter Email"),
      job_role: Yup.string().required("Please Enter Job Role"),
      date: Yup.string().required("Please Enter Date"),
      emergency_Name: Yup.string().required("Please Emergency Name"),
    }),

    onSubmit: values => {
      if (isEdit) {
        const obj = [
          {
            Id:
              profile?.userRoles?.length === 0
                ? 0
                : profile?.userRoles?.[0]?.id,
            Role_Id: JSON.parse(values.job_role),
            User_Id: profile ? profile?.id : 0,
          },
        ]
        const jionobj = [
          {
            Id: profile?.userTypes?.[0]?.id,
            Type_Id: profile?.userTypes?.[0]?.type_Id,
            User_Id: profile ? profile?.id : 0,
          },
        ]
        var datas = new FormData()
        if (selectedFiles.length > 0) {
          selectedFiles.map(selectedFile => {
            datas.append("SES_Card_Qualification", selectedFile)
          })
        } else {
          datas.append("SES_Card_Qualification", null)
        }
        datas.append("Card_Number", values.cardNumber)
        datas.append("ExpiryDate", values.expiryDate)
        datas.append("Username", values.username)
        datas.append("Name", values.name)
        datas.append("Address", values.address)
        datas.append("Number", values.number)
        datas.append("Emergency_Contact", values.emergency_Contact)
        datas.append("company_Name", values.comapany_name)
        datas.append("UserRoles", JSON.stringify(obj))
        datas.append("UserTypes", JSON.stringify(jionobj))
        datas.append("Emergency_Name", values.emergency_Name)
        datas.append("DOB", values.date)
        datas.append("Id", profile ? profile.id : 0)
        // update order
        dispatch(updateUser(datas, handleResponse))
        // validation.resetForm()
      }
      // toggle()
    },
  })
  const handleResponse = status => {
    if (status === "SUCCESS") {
      toggle()
      validation.resetForm()
    }
  }
  const params = {
    pagenumber: pageindex + 1,
    pagesize: pageSize,
    sortKey: sort.accessor,
    sortValue: sort.direction,
    username: filterUserEmail,
    name: filterName,
    userTypes: filterUserType,
    emergencyContact: filterEmgerencyno,
    emergencyName: filterEmgerencyname,
    contactnumber: filterContactno,
    address: filterAddress,
    userrole: filterJobrole,
  }
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(onGetUser(params))
    dispatch(onGetRoles())
  }, [dispatch])

  const toggle = () => {
    if (modal) {
      setModal(false)
      validation.resetForm()
      setSelectedFiles([])
    } else {
      setModal(true)
    }
  }
  useEffect(() => {
    if (success === "Update Successfully.") {
      dispatch(onGetUser(params))
    }
  }, [success])
  useEffect(() => {
    dispatch(ChangeHeading("PROFILE"))
  }, [])

  //delete order
  const [deleteModal, setDeleteModal] = useState(false)

  const handleProfileClicks = () => {
    setIsEdit(false)
    setProfile({})
    toggle()
  }
  const onClickDelete = order => {
    setOrder(order)
    setDeleteModal(true)
  }

  const handleRowClick = arg => {
    const profile = arg
    setProfile({
      ...profile,
    })
    setIsEdit(true)
    toggle()
  }
  const handleDeleteOrder = () => {
    if (order.id) {
      dispatch(deleteUser(order.id))
      setDeleteModal(false)
    }
  }

  const handleFileRemovedata = userData => {
    const delAttch = {
      id: userData?.attachments?.id,
      user_Id: userData?.user_Id,
      attachment_Id: userData?.attachment_Id,
    }

    let userAttachments = profile.userAttachments.filter(
      attachment => attachment?.attachments?.id != userData?.attachments?.id
    )

    setProfile({
      ...profile,
      userAttachments,
    })
    // return false
    dispatch(deleteUserAttc(delAttch))
  }
  const columnHeaderClick = (column, pagesize, pageIndex) => {
    switch (column.sortDirection) {
      case "none":
        setSort({ direction: "asc", accessor: column.sortName })
        handlePageValue({
          pageSize: pagesize,
          pageNumber: pageIndex,
          sortKey: column.sortName,
          sortValue: "asc",
        })
        break
      case "asc":
        setSort({ direction: "desc", accessor: column.sortName })
        handlePageValue({
          pageSize: pagesize,
          pageNumber: pageIndex,

          sortKey: column.sortName,
          sortValue: "desc",
        })
        break
      case "desc":
        setSort({ direction: "asc", accessor: column.sortName })
        handlePageValue({
          pageSize: pagesize,
          pageNumber: pageIndex,
          sortKey: column.sortName,
          sortValue: "asc",
        })
        break
    }
  }
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        disableFilters: true,
        sortName: "Name",
        sortDirection: sort.accessor === "Name" ? sort.direction : "none",
      },
      {
        Header: "Email",
        accessor: "username",
        disableFilters: true,
        sortName: "UserName",
        sortDirection: sort.accessor === "UserName" ? sort.direction : "none",
      },
      {
        Header: "Contact Number",
        accessor: "number",
        sortName: "Contact",
        disableFilters: true,
        sortDirection: sort.accessor === "Contact" ? sort.direction : "none",
      },
      {
        Header: "CSCS Card Number",
        accessor: "card_Number",
        disableFilters: true,
      },

      {
        Header: "CSCS Card Expiry",
        accessor: "expiryDate",
        disableFilters: true,
      },

      {
        Header: "Company Name",
        accessor: "company_Name",
        disableFilters: true,
      },
      {
        Header: "Job Role",
        accessor: "userRoles[0].roles.name",
        sortName: "UserRole",
        disableFilters: true,
        sortDirection: sort.accessor === "UserRole" ? sort.direction : "none",
      },

      {
        Header: "Address",
        accessor: "address",
        disableFilters: true,
        sortName: "Address",
        sortDirection: sort.accessor === "Address" ? sort.direction : "none",
      },
      {
        Header: "Emergency Name",
        accessor: "emergency_Name",
        disableFilters: true,
        sortName: "EmergencyName",
        sortDirection:
          sort.accessor === "EmergencyName" ? sort.direction : "none",
      },

      {
        Header: "Emergency Contact",
        accessor: "emergency_Contact",
        disableFilters: true,
        sortName: "EmergencyContact",
        sortDirection:
          sort.accessor === "EmergencyContact" ? sort.direction : "none",
      },

      {
        Header: " ",
        Cell: cellProps => {
          return (
            <div
              className="d-flex gap-3 absolute"
              style={{ cursor: "Pointer" }}
            >
              <UncontrolledDropdown>
                <DropdownToggle href="#" className="card-drop" tag="i">
                  <i className="mdi mdi-dots-horizontal font-size-18" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem
                    href="#"
                    onClick={() => handleRowClick(cellProps.row.original)}
                  >
                    <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    href="#"
                    onClick={() => onClickDelete(cellProps.row.original)}
                  >
                    <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          )
        },
      },
    ],
    [rolesType, sort]
  )
  const handlePageValue = value => {
    if (!value.noChange) setpageindex(value.pageNumber)
    setpageSize(value.pageSize)
    dispatch(
      onGetUser({
        ...value,
        pageNumber: value.pageNumber + 1,
        username: filterUserEmail,
        name: filterName,
        userTypes: filterUserType,
        emergencyContact: filterEmgerencyno,
        emergencyName: filterEmgerencyname,
        contactnumber: filterContactno,
        address: filterAddress,
        userrole: filterJobrole,
      })
    )
  }
  const handleSubmit = e => {
    e.preventDefault()
    dispatch(
      onGetUser({
        pageNumber: pageindex + 1,
        pageSize: pageSize,
        username: filterUserEmail,
        name: filterName,
        userTypes: filterUserType,
        emergencyContact: filterEmgerencyno,
        emergencyName: filterEmgerencyname,
        contactnumber: filterContactno,
        address: filterAddress,
        userrole: filterJobrole,
      })
    )
  }

  const removeValues = () => {
    setFilterUserEmail("")
    if (filterName?.length > 0) {
      dispatch(
        onGetUser({
          pageNumber: 1,
          pageSize: pageSize,
          userEmail: "",
          Name: filterName || "",
          usertype: filterUserType || "",
          emergencyContact: filterEmgerencyno || "",
          emergencyName: filterEmgerencyname || "",
          contactnumber: filterContactno || "",
          address: filterAddress || "",
          userrole: filterJobrole || "",
        })
      )
    } else {
      dispatch(
        onGetUser({
          pageNumber: 1,
          pageSize: pageSize,
          userEmail: "",
          Name: "",
          usertype: "",
          emergencyContact: "",
          emergencyName: "",
          contactnumber: "",
          address: "",
          userrole: "",
        })
      )
    }
  }
  const removeValuesName = () => {
    setFilterName("")
    if (filterName?.length > 0) {
      dispatch(
        onGetUser({
          pageNumber: 1,
          pageSize: pageSize,
          userEmail: filterUserEmail || "",
          firstName: "",
          usertype: filterUserType || "",
          emergencyContact: filterEmgerencyno || "",
          emergencyName: filterEmgerencyname || "",
          contactnumber: filterContactno || "",
          address: filterAddress || "",
          userrole: filterJobrole || "",
        })
      )
    } else {
      dispatch(
        onGetUser({
          pageNumber: 1,
          pageSize: pageSize,
          userEmail: "",
          Name: "",
          usertype: "",
          emergencyContact: "",
          emergencyName: "",
          contactnumber: "",
          address: "",
          userrole: "",
        })
      )
    }
  }

  const removeValuesUserType = () => {
    setFilterUserType("")
    if (filterUserType?.length > 0) {
      dispatch(
        onGetUser({
          pageNumber: 1,
          pageSize: pageSize,
          userEmail: filterUserEmail || "",
          Name: filterName || "",
          usertype: "",
          emergencyContact: filterEmgerencyno || "",
          emergencyName: filterEmgerencyname || "",
          contactnumber: filterContactno || "",
          address: filterAddress || "",
          userrole: filterJobrole || "",
        })
      )
    } else {
      dispatch(
        onGetUser({
          pageNumber: 1,
          pageSize: pageSize,
          userEmail: "",
          userName: "",
          emergencyContact: "",
          emergencyName: "",
          contactnumber: "",
          address: "",
          userrole: "",
        })
      )
    }
  }

  const removeValuesEmgerencyno = () => {
    setFilterEmgerencyno("")
    if (filterEmgerencyno?.length > 0) {
      dispatch(
        onGetUser({
          pageNumber: 1,
          pageSize: pageSize,
          userEmail: filterUserEmail || "",
          Name: filterName || "",
          usertype: filterUserType || "",
          emergencyContact: "",
          emergencyName: filterEmgerencyname || "",
          contactnumber: filterContactno || "",
          address: filterAddress || "",
          userrole: filterJobrole || "",
        })
      )
    } else {
      dispatch(
        onGetUser({
          pageNumber: 1,
          pageSize: pageSize,
          userEmail: "",
          userName: "",
          usertype: "",
          emergencyName: "",
          contactnumber: "",
          address: "",
          userrole: "",
        })
      )
    }
  }
  const removeValuesContactno = () => {
    setFilterContactno("")
    if (filterContactno?.length > 0) {
      dispatch(
        onGetUser({
          pageNumber: 1,
          pageSize: pageSize,
          userEmail: filterUserEmail || "",
          Name: filterName || "",
          usertype: filterUserType || "",
          emergencyContact: filterEmgerencyno || "",
          emergencyName: filterEmgerencyname || "",
          contactnumber: "",
          address: filterAddress || "",
          userrole: filterJobrole || "",
        })
      )
    } else {
      dispatch(
        onGetUser({
          pageNumber: 1,
          pageSize: pageSize,
          userEmail: "",
          Name: "",
          usertype: "",
          emergencyContact: "",
          emergencyName: "",
          contactnumber: "",
          address: "",
          userrole: "",
        })
      )
    }
  }

  const removeValuesEmgerencyname = () => {
    setFilterEmgerencyname("")
    if (filterEmgerencyname?.length > 0) {
      dispatch(
        onGetUser({
          pageNumber: 1,
          pageSize: pageSize,
          userEmail: filterUserEmail || "",
          Name: filterName || "",
          usertype: filterUserType || "",
          emergencyContact: filterEmgerencyno || "",
          emergencyName: "",
          contactnumber: filterContactno || "",
          address: filterAddress || "",
          userrole: filterJobrole || "",
        })
      )
    } else {
      dispatch(
        onGetUser({
          pageNumber: 1,
          pageSize: pageSize,
          userEmail: "",
          Name: "",
          usertype: "",
          emergencyContact: "",
          contactnumber: "",
          address: "",
          userrole: "",
        })
      )
    }
  }

  const removeValuesAddress = () => {
    setFilterJobrole("")
    if (filterJobrole?.length > 0) {
      dispatch(
        onGetUser({
          pageNumber: 1,
          pageSize: pageSize,
          userEmail: filterUserEmail || "",
          Name: filterName || "",
          usertype: filterUserType || "",
          emergencyContact: filterEmgerencyno || "",
          emergencyName: filterEmgerencyname || "",
          contactnumber: filterContactno || "",
          address: filterAddress || "",
          userrole: "",
        })
      )
    } else {
      dispatch(
        onGetUser({
          pageNumber: 1,
          pageSize: pageSize,
          userEmail: "",
          Name: "",
          usertype: "",
          emergencyContact: "",
          emergencyName: "",
          contactnumber: "",
          address: "",
          userrole: "",
        })
      )
    }
  }
  const removeValuesJobrole = () => {
    setFilterAddress("")
    if (filterAddress?.length > 0) {
      dispatch(
        onGetUser({
          pageNumber: 1,
          pageSize: pageSize,
          userEmail: filterUserEmail || "",
          Name: filterName || "",
          usertype: filterUserType || "",
          emergencyContact: filterEmgerencyno || "",
          emergencyName: filterEmgerencyname || "",
          contactnumber: filterContactno || "",
          address: "",
          userrole: filterJobrole || "",
        })
      )
    } else {
      dispatch(
        onGetUser({
          pageNumber: 1,
          pageSize: pageSize,
          userEmail: "",
          Name: "",
          usertype: "",
          emergencyContact: "",
          emergencyName: "",
          contactnumber: "",
          address: "",
          userrole: "",
        })
      )
    }
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Dashboard" breadcrumbItem="Profile" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <SearchForm
                    setFilterUserEmail={setFilterUserEmail}
                    filterUserEmail={filterUserEmail}
                    setFilterUserType={setFilterUserType}
                    filterUserType={filterUserType}
                    handleSubmit={handleSubmit}
                    removeValues={removeValues}
                    removeValuesUserType={removeValuesUserType}
                    removeValuesName={removeValuesName}
                    setFilterName={setFilterName}
                    filterName={filterName}
                    handleOrSiteClicks={handleProfileClicks}
                    filterEmgerencyno={filterEmgerencyno}
                    setFilterEmgerencyno={setFilterEmgerencyno}
                    filterContactno={filterContactno}
                    setFilterContactno={setFilterContactno}
                    filterEmgerencyname={filterEmgerencyname}
                    setFilterEmgerencyname={setFilterEmgerencyname}
                    filterJobrole={filterJobrole}
                    setFilterJobrole={setFilterJobrole}
                    filterAddress={filterAddress}
                    setFilterAddress={setFilterAddress}
                    removeValuesEmgerencyno={removeValuesEmgerencyno}
                    removeValuesContactno={removeValuesContactno}
                    removeValuesEmgerencyname={removeValuesEmgerencyname}
                    removeValuesJobrole={removeValuesJobrole}
                    removeValuesAddress={removeValuesAddress}
                  />
                  <TableContainer
                    RowClick={true}
                    columns={columns}
                    data={users || []}
                    buttonname="Add New Profile"
                    handleOrSiteClicks={handleProfileClicks}
                    customPageSize={10}
                    className="custom-header-css"
                    pageindex={pageindex}
                    setpageindex={setpageindex}
                    handlePageValue={handlePageValue}
                    totalCount={totalcount || 0}
                    handleRowClick={handleRowClick}
                    onHeaderClick={columnHeaderClick}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Profile" : "Add Profile"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row form>
                  <Col className="col-6 form_align">
                    <div className="mb-3">
                      <Label className="form-label">
                        Name <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        name="name"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                          validation.touched.name && validation.errors.name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.name}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">
                        Contact Number <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        name="number"
                        type="tel"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.number || ""}
                        invalid={
                          validation.touched.number && validation.errors.number
                            ? true
                            : false
                        }
                      />
                      {validation.touched.number && validation.errors.number ? (
                        <FormFeedback type="invalid">
                          {validation.errors.number}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">
                        Address <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        name="address"
                        type="textarea"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.address || ""}
                        invalid={
                          validation.touched.address &&
                          validation.errors.address
                            ? true
                            : false
                        }
                      />
                      {validation.touched.address &&
                      validation.errors.address ? (
                        <FormFeedback type="invalid">
                          {validation.errors.address}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">
                        Email <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        name="username"
                        type="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.username || ""}
                        invalid={
                          validation.touched.username &&
                          validation.errors.username
                            ? true
                            : false
                        }
                      />
                      {validation.touched.username &&
                      validation.errors.username ? (
                        <FormFeedback type="invalid">
                          {validation.errors.username}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">
                        Company Name (Optional)
                      </Label>
                      <Input
                        name="comapany_name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.comapany_name || ""}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">
                        D.O.B <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        name="date"
                        type="date"
                        format="YYYY MM DD"
                        max={disablePastDate()}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        // value={validation.values.dates || ""}
                        value={
                          moment(new Date(validation.values.date)).format(
                            "YYYY-MM-DD"
                          ) || ""
                        }
                        invalid={
                          validation.touched.date && validation.errors.date
                            ? true
                            : false
                        }
                      />
                      {validation.touched.date && validation.errors.date ? (
                        <FormFeedback type="invalid">
                          {validation.errors.date}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">
                        Job Role <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        id="job_role"
                        type="select"
                        name="job_role"
                        value={validation.values.job_role || ""}
                        onBlur={validation.handleBlur}
                        onChange={validation.handleChange}
                        invalid={
                          validation.touched.job_role &&
                          validation.errors.job_role
                            ? true
                            : false
                        }
                      >
                        <option key={`optionVal_empty`} value="">
                          Please Select Roles
                        </option>
                        {(rolesType || [])?.map((option, i) => (
                          <option key={`optionVal_${i}`} value={option?.id}>
                            {option?.name}
                          </option>
                        ))}
                      </Input>

                      {validation.touched.job_role &&
                      validation.errors.job_role ? (
                        <FormFeedback type="invalid">
                          {validation.errors.job_role}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col className="col-6 form_align">
                    <div className="mb-3">
                      <Label className="form-label">
                        Emergency Name <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        name="emergency_Name"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.emergency_Name || ""}
                        invalid={
                          validation.touched.emergency_Name &&
                          validation.errors.emergency_Name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.emergency_Name &&
                      validation.errors.emergency_Name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.emergency_Name}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">
                        Emergency Contact Number{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        name="emergency_Contact"
                        type="tel"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.emergency_Contact || ""}
                        invalid={
                          validation.touched.emergency_Contact &&
                          validation.errors.emergency_Contact
                            ? true
                            : false
                        }
                      />
                      {validation.touched.emergency_Contact &&
                      validation.errors.emergency_Contact ? (
                        <FormFeedback type="invalid">
                          {validation.errors.emergency_Contact}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="">
                      <Label className="form-label">CSCS Card Proof</Label>
                    </div>
                    <Form>
                      <Dropzone
                        onDrop={acceptedFiles => {
                          handleAcceptedFiles(acceptedFiles)
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick mt-2"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div
                        className="dropzone-previews my-3"
                        id="file-previews"
                      >
                        {selectedFiles.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col>
                                    <p className="mb-0 text-muted font-weight-bold">
                                      {f.name}
                                    </p>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                    <button
                                      type="button"
                                      className="btn-close"
                                      aria-label="Close"
                                      style={{
                                        position: "absolute",
                                        right: "10px",
                                        top: 0,
                                        cursor: "pointer",
                                      }}
                                      onClick={() => handleFileRemoves(i)}
                                    ></button>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          )
                        })}
                        {!!isEdit === false ||
                        profile?.userAttachments?.length == 0 ? null : (
                          <div>
                            {profile?.userAttachments?.map(i => (
                              <div
                                className="files_ui"
                                key={i?.attachments?.id}
                              >
                                <a
                                  key={i?.attachments?.id}
                                  href={i?.attachments?.fileURL}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="files_ui_link"
                                >
                                  {i?.attachments?.fileName}
                                </a>
                                <button
                                  type="button"
                                  className="btn-close"
                                  aria-label="Close"
                                  style={{
                                    position: "absolute",
                                    right: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleFileRemovedata(i)}
                                ></button>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>

                      <div className="mb-3">
                        <Col className="col-12 form_align">
                          <Label className="form-label">CSCS Card Number</Label>
                          <Input
                            type="number"
                            className="form-control input-color"
                            id="cardNumber"
                            // placeholder="XXXXXXXXXXXXXXXXXXXX"

                            value={validation.values.cardNumber || ""}
                            onChange={validation.handleChange}
                          />

                        </Col>

                        <Col className="col-12 form_align">
                          <Label className="form-label">Valid Till</Label>
                          <InputMask
                            name="expiryDate"
                            type="text"
                            mask="99/99"
                            className="mobile_expiry form-control input-color"
                            placeholder="MM/YY"
                            onChange={validation.handleChange}
                            // value={
                            //   this.props.expiryDates || this.props.expiryDate
                            // }
                            value={validation.values.expiryDate || ""}
                          />
                        </Col>
                      </div>
                    </Form>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}
Profile.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Profile
