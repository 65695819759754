import {
  GET_SITES_SUCCESS,
  GET_SITES_FAIL,
  POST_ADD_SITE_SUCCESS,
  POST_ADD_SITE_FAIL,
  POST_UPDATE_SITE,
  POST_UPDATE_SITE_SUCCESS,
  POST_UPDATE_SITE_FAIL,
  POST_DELETE_SITE,
  POST_DELETE_SITE_SUCCESS,
  POST_DELETE_SITE_FAIL,
  POST_ADD_CLOCK_SUCCESS,
  GET_CLOCK_FAIL,
  GET_CLOCK_SUCCESS,
  POST_DELETE_ATTC_SUCCESS,
  POST_DELETE_ATTC_FAIL,
  POST_UP_CLOCK_SUCCESS,
  GET_CHECK_IN_SUCCESS,
  GET_CHECK_IN_FAIL
} from "./actionTypes"

const INIT_STATE = {
  sites: [],
  clock: [],
  count: [],
  siteCount: [],
  attc: [],
  error: {},
  successsAttc: [],
  success: null,
  successs: null,
  createSuccess: null,
  workersLog:{}
}

const Calendar = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SITES_SUCCESS:
      let Getlists = action.payload.sites?.filter(v => v)
      let siteCount = action.payload?.rowCount
      return {
        ...state,
        sites: Getlists,
        createSuccess: [],
        siteCount: siteCount,
      }

    case GET_SITES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case POST_ADD_SITE_SUCCESS:
      return {
        ...state,
        sites: [...state.sites, action.payload],
        createSuccess: action.payload,
      }

    case POST_ADD_SITE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // update site
    case POST_UPDATE_SITE_SUCCESS:
      const updated = state.sites?.filter(v => v)
      const updatedData = updated.map(item => {
        if (item.id == action.payload.id) {
          return action.payload
        }
        return item
      })
      return {
        ...state,
        sites: updatedData,
        successsAttc: action.payload,
      }

    case POST_UPDATE_SITE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // delete
    case POST_DELETE_SITE_SUCCESS:
      let deleted = state.sites?.filter(v => v)
      let deleteFilter = deleted.filter(item => item.id !== action.payload)
      return {
        ...state,
        sites: deleteFilter,
      }

    case POST_DELETE_SITE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case POST_ADD_CLOCK_SUCCESS:
      // const clockssadd = state.clock?.filter(v => v)
      // const addclocks = clockssadd?.map(item => {
      //   if (item.id == action.payload.id) {
      //     // merges = { ...action.payload?.workersLog?.status }
      //     // ss = join(merges)
      //     // console.log(ss, "sssss")
      //     // item = { ...item, status: { ...item?.status, ss } }
      //     return action.payload
      //   }
      //   return item
      // })

      return {
        ...state,
        // clock: addclocks,
        success: action.payload,
        workersLog:action.payload?.workersLog,
      }

    case POST_ADD_SITE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    // update clock success
    case POST_UP_CLOCK_SUCCESS:
      // const clockss = state.clock?.filter(v => v)
      // const updatedclocks = clockss?.map(item => {

      //   if (item.id == action.payload.id) {
      //     // item = {...item, status: {...item?.status, ...action.payload?.workersLog?.status}};
      //     // var merges1 = [...action.payload?.workersLog?.status]
      //     // let text = merges1.toString().replace(/,/g, "");
      //     // item = { ...item, status: text }
      //     return action.payload
      //     // return item
      //   }
      //   return item
      // })

      return {
        ...state,
        // clock: updatedclocks,
        successs: action.payload,
        workersLog:action.payload?.workersLog,
      }

    case POST_UPDATE_SITE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    //Get  clock in clock out
    case GET_CLOCK_SUCCESS:
      // let Getlistss = action.payload?.result?.workersLog?.filter(v => v)
      let Getlistss = action.payload?.result?.workersLog?.filter(v => v)
      let Count = action.payload?.result?.rowCount
      return {
        ...state,
        clock: Getlistss,
        count: Count,
      }

    case GET_CLOCK_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case POST_DELETE_ATTC_SUCCESS:
      let deleteFilters = state.sites?.map(item => {
        if (item.id == action.payload.sites_Id) {
          delete item.sitesAttachments
          return item
        }
        return item
      })

      return {
        ...state,
        sites: deleteFilters,
      }

    case POST_DELETE_ATTC_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CHECK_IN_SUCCESS:
      return {
        ...state,
        workersLog:action.payload?.works,
      }

    case GET_CHECK_IN_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Calendar
