//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"

//Mails
export const GET_INBOX_MAILS = "/inboxmails"
export const ADD_NEW_INBOX_MAIL = "/add/inboxmail"
export const DELETE_INBOX_MAIL = "/delete/inboxmail"

//starred mail
export const GET_STARRED_MAILS = "/starredmails"

//important mails
export const GET_IMPORTANT_MAILS = "/importantmails"

//Draft mail
export const GET_DRAFT_MAILS = "/draftmails"

//Send mail
export const GET_SENT_MAILS = "/sentmails"

//Trash mail
export const GET_TRASH_MAILS = "/trashmails"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"

//NavHeading

//ORDERS
export const GET_ORDERS = "/orders"
export const ADD_NEW_ORDER = "/add/order"
export const UPDATE_ORDER = "/update/order"
export const DELETE_ORDER = "/delete/order"

//CART DATA
export const GET_CART_DATA = "/cart"

//CUSTOMERS
export const GET_CUSTOMERS = "/customers"
export const ADD_NEW_CUSTOMER = "/add/customer"
export const UPDATE_CUSTOMER = "/update/customer"
export const DELETE_CUSTOMER = "/delete/customer"

//SHOPS
export const GET_SHOPS = "/shops"

//CRYPTO
export const GET_WALLET = "/wallet"
export const GET_CRYPTO_ORDERS = "/crypto/orders"

//INVOICES
export const GET_INVOICES = "/invoices"
export const GET_INVOICE_DETAIL = "/invoice"

//PROJECTS
export const GET_PROJECTS = "/projects"
export const GET_PROJECT_DETAIL = "/project"
export const ADD_NEW_PROJECT = "/add/project"
export const UPDATE_PROJECT = "/update/project"
export const DELETE_PROJECT = "/delete/project"

//TASKS
export const GET_TASKS = "/tasks"

//CONTACTS
export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/user"
export const ADD_NEW_USER = "/add/user"
export const UPDATE_USER = "/update/user"
export const DELETE_USER = "/delete/user"

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data"
export const GET_YEARLY_DATA = "/yearly-data"
export const GET_MONTHLY_DATA = "/monthly-data"

//New Dashboard
export const GET_NAV_TITLE = "/title"

//end

export const TOP_SELLING_DATA = "/top-selling-data"

export const GET_EARNING_DATA = "/earning-charts-data"

export const GET_PRODUCT_COMMENTS = "/comments-product"

export const ON_LIKNE_COMMENT = "/comments-product-action"

export const ON_ADD_REPLY = "/comments-product-add-reply"

export const ON_ADD_COMMENT = "/comments-product-add-comment"

// New API URL's started
export const LOGIN_API_URL = "/Account/Login"
export const REGISTER_API_URL = "/Account"
export const FORGOT_API_URL = "/Account/ForgotPassword"

// Site API URL's
export const GET_SITE_API_URL = "/Sites/GetSitesList"
export const POST_SITE_API_URL = "/Sites/CreateSite"
export const UPDATE_SITE_API_URL = "/Sites/UpdateSite"
export const DELETE_SITE_API_URL = "/Sites/DeleteSite"

// User API URL's
export const GET_USER_API_URL = "/Account/GetUsersList"
export const POST_USER_API_URL = "/Account/CreateAccount"
export const UPDATE_USER_API_URL = "/Account/EditUser"
export const DELETE_USER_API_URL = "/Account/DeleteAccount"

// Get Roles and Types
export const GET_ROLES_API_URL = "/Account/GetRolesList"
export const GET_TYPES_API_URL = "/Account/GetTypeList"

// Attachement User
export const POST_ATTACHEMENT_API_URL = "/SendAttachment"

// Forget User
export const POST_FORGET_API_URL = "/Account/ForgotPassword"

//token get
export const POST_TOKEN_API_URL = "/Account/ResetPassword"

// reset password

export const POST_RESET_API_URL = "/Account/ChangePassword"

export const POST_CLOCK_API_URL = "/WorkLog/CreateWorkLog"

export const POST_CLOCKUP_API_URL = "/WorkLog/UpdateWorkLog"

export const GET_CLOCK_API_URL = "/WorkLog/GetWorksLogList"

export const DELETE_ATTC_API_URL = "/Sites/DeleteSiteAttachment"

export const DELETE_ATTC_USER_API_URL = "/Account/DeleteUserAttachment"

export const POST_NOTIFICATION_API_URL = "/api/notification/send"

export const GET_CHECK_IN_API_URL = "/Account/GetRecentLogged"

export const GET_SOS = "/Settings/GetSettingsList"

export const POST_ADD_SOS = "/Settings/CreateSettings"

export const POST_UPDATE_SOS = "/Settings/UpdateSettings"
