import {
    API_SUCCESS,
    API_FAIL,
    GET_CHARTS_DATA,
    CHANGE_HEADING,
    USER_ROLE,
    GET_SOS,
    GET_SOS_SUCCESS,
    GET_SOS_FAIL,
    ADD_SOS,
    ADD_SOS_SUCCESS,
    ADD_SOS_FAIL,
    UPDATE_SOS,
    UPDATE_SOS_SUCCESS,
    UPDATE_SOS_FAIL,
    TOGGLE_MODAL
} from "./actionTypes";

export const apiSuccess = (actionType, data) => ({
    type: API_SUCCESS,
    payload: { actionType, data },
});

export const apiFail = (actionType, error) => ({
    type: API_FAIL,
    payload: { actionType, error },
});

// charts data
export const getChartsData = (periodType) => ({
    type: GET_CHARTS_DATA,
    payload: periodType
});
export const ChangeHeading = (heading) => ({
    type: CHANGE_HEADING,
    payload: heading ,
  })
export const LoginUser = role => ({
    type: USER_ROLE,
    payload: role ,
  })
  export const apititleSuccess = (actionType, data) => (
    {
    type: API_SUCCESS,
    payload: { actionType, data },
});

export const apititleFail = (actionType, error) => ({
    type: API_FAIL,
    payload: { actionType, error },
});

export const getSOS = () => ({
    type: GET_SOS
});

export const getSOSNumberSuccess = (data) => ({
    type: GET_SOS_SUCCESS,
    payload: data,
});

export const getSOSNumberFail = (data) => ({
    type: GET_SOS_FAIL,
    payload: data,
});

/*Add sos */
export const addSOS = (data) => ({
    type: ADD_SOS,
    payload: data,
});

export const addSOSNumberSuccess = (data) => ({
    type: ADD_SOS_SUCCESS,
    payload: data,
});

export const addSOSNumberFail = (data) => ({
    type: ADD_SOS_FAIL,
    payload: data,
});


/*Update sos */
export const updateSOS = (data) => ({
    type: UPDATE_SOS,
    payload: data
});

export const updateSOSNumberSuccess = (data) => ({
    type: UPDATE_SOS_SUCCESS,
    payload: data,
});

export const updateSOSNumberFail = (data) => ({
    type: UPDATE_SOS_FAIL,
    payload: data,
});

export const toggleModal = data => ({
    type: TOGGLE_MODAL,
  })

