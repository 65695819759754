import React from "react"
import { Input, CustomInput } from "reactstrap"

export const Filter = ({ column }) => {
  return (
    <div style={{ marginTop: 5 }} className="hide_searchbox">
      {column.canFilter && column.render("Filter")}
    </div>
  )
}
export const Filters = ({ column }) => {
  return (
    <div style={{ marginTop: 5 }} className="Mobile_hide_searchbox">
      {column.canFilter && column.render("Filter")}
    </div>
  )
}

export const DefaultColumnFilter = ({
  column: {
    filterValue,
    setFilter,
    preFilteredRows: { length },
  },
}) => {
  const removeValues = () => {
    setFilter("")
  }
  return (
    <div className="position-relative mt-1">
      <Input
        value={filterValue || ""}
        style={{ paddingRight: "1.8rem" }}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
      />
      <button
        type="button"
        className="close"
        aria-label="Close"
        style={{ marginTop: "-3px"}}
        onClick={removeValues}
      >
        <span aria-hidden="true">&times;</span>
      </button>
      {/* <i
        className="bx bx-search-alt search-icon"
        style={{ position: "absolute", top: "12px", left: "5px" }}
      ></i> */}
    </div>
  )
}

export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  return (
    <select
      id="custom-select"
      className="form-select"
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">All</option>
      {options.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}
