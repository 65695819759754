export const formtojson = formData => {
  let object = {};
  formData.forEach(function (value, key) {
    let sKey = key.charAt(0).toLowerCase() + key.slice(1);
    object[sKey] = value;
  })
  return object;
}

export const formatojson = formData => {
  let object = Object.keys(formData).reduce((value, key) => {
    value[key.charAt(0).toLowerCase() + key.slice(1)] = formData[key];
    return value;
  }, {});
  return object;
}
