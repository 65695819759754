import React from "react"
import { takeEvery, put, call } from "redux-saga/effects"
import toastr from "toastr"
import { togglePageLoader } from "store/actions"

// Chat Redux States
import {
  GET_USER,
  POST_ADD_USER,
  POST_DELETE_USER,
  POST_UPDATE_USER,
  GET_ROLES,
  GET_TYPES,
  POST_ATTACHEMENT_USER,
  POST_FORGET_USER,
  POST_RESET_USER,
  POST_TOKEN_USER,
  POST_DELETE_ATTC_USER,
  POST_UPDATE_USERS,
} from "./actionTypes"
import {
  getUserSuccess,
  getUserFail,
  addUserSuccesss,
  addUserFails,
  delUserSuccesss,
  delUserFails,
  upUserSuccesss,
  upUserFails,
  getRolesSuccess,
  getRolesFail,
  getTypesSuccess,
  getTypesFail,
  attachementUserSuccesss,
  attachementUserFails,
  forgetUserSuccesss,
  forgetUserFails,
  resetUserSuccesss,
  resetUserFails,
  tokenUserSuccesss,
  tokenUserFails,
  delUserAttcFails,
  delUserAttcSuccesss,
  upUsersSuccesss,
  upUsersFails,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getUser,
  addUser,
  delUser,
  upUser,
  getRoles,
  getTypes,
  attachementUser,
  forgetUser,
  resetUser,
  tokenUser,
  delUserAttcs,
} from "../../helpers/site_backend_helper"

import { formtojson } from "helpers/util"
import { Redirect } from "react-router-dom"

function* onGetUser({ payload: data }) {
  try {
    yield put(togglePageLoader(true))
    const response = yield call(getUser, data)
    yield put(getUserSuccess(response))
    yield put(addUserSuccesss(null))
    yield put(upUserSuccesss(null))
    yield put(upUsersSuccesss(null))
    yield put(togglePageLoader(false))
  } catch (error) {
    yield put(getUserFail(error))
    yield put(togglePageLoader(false))
    if (error?.response?.status == 401) {
      localStorage.removeItem("authUser")
      localStorage.removeItem("authTokenDisplay"),
      <Redirect to="/logout" />
    }
  }
}

function* onAddUser({ message, callback }) {
  try {
    yield put(togglePageLoader(true))
    const response = yield call(addUser, message)
    if (response.statusCode == -1) {
      toastr.error(response?.statusMessage)
      yield put(addUserSuccesss({ ...message, ...response }))
      yield put(togglePageLoader(false))
      callback("FAILURE")
    } else {
      yield put(addUserSuccesss({ ...message, ...response }))
      toastr.success(response.statusMessage)
      callback("SUCCESS")
    }
    setTimeout(() => toastr.clear(), 3000)
    yield put(togglePageLoader(false))
  } catch (error) {
    toastr.error(error)
    setTimeout(() => toastr.clear(), 3000)
    yield put(togglePageLoader(false))
    yield put(addUserFails(error))
  }
}

function* updateUser({ id, callback }) {
  try {
    yield put(togglePageLoader(true))
    const response = yield call(upUser, id)
    if (response.statusCode == -1) {
      toastr.error(response?.statusMessage)
      yield put(upUserSuccesss({ ...formtojson(id), ...response }))
      yield put(togglePageLoader(false))
    } else {
      yield put(upUserSuccesss({ ...formtojson(id), ...response }))
      toastr.success(response?.statusMessage)
      callback("SUCCESS")
    }
    yield put(togglePageLoader(false))
    setTimeout(() => toastr.clear(), 3000)
  } catch (error) {
    toastr.error(error)
    setTimeout(() => toastr.clear(), 3000)
    yield put(togglePageLoader(false))
    yield put(upUserFails(error))
    callback("FAILURE")
  }
}

function* updateUsers({ id, callback }) {
  try {
    yield put(togglePageLoader(true))
    const response = yield call(upUser, id)

    if (response.statusCode == -1) {
      toastr.error(response?.statusMessage)
      yield put(togglePageLoader(false))
      yield put(upUsersSuccesss({ ...formtojson(id), ...response }))
      yield put(togglePageLoader(false))
      callback("FAILURE")
    } else {
      yield put(upUsersSuccesss({ ...formtojson(id), ...response }))
      toastr.success(response?.statusMessage)
      yield put(togglePageLoader(false))
      callback("SUCCESS")
    }
    setTimeout(() => toastr.clear(), 3000)
  } catch (error) {
    toastr.error(error)
    setTimeout(() => toastr.clear(), 3000)
    yield put(togglePageLoader(false))
    yield put(upUsersFails(error))
    callback("FAILURE")
  }
}

function* deleteUser({ id }) {
  try {
    yield put(togglePageLoader(true))
    const response = yield call(delUser, id)
    toastr.success("User Deleted Successfully")
    yield put(togglePageLoader(false))
    setTimeout(() => toastr.clear(), 3000)
    yield put(delUserSuccesss(id))
  } catch (error) {
    toastr.error(error)
    setTimeout(() => toastr.clear(), 3000)
    yield put(togglePageLoader(false))
    yield put(delUserFails(error))
  }
}

// Gedt roles and type in database
function* onGetRoles({ roomId }) {
  try {
    const response = yield call(getRoles, roomId)
    yield put(getRolesSuccess(response))
  } catch (error) {
    yield put(getRolesFail(error))
    if (error?.response?.status == 401) {
      localStorage.removeItem("authUser")
      localStorage.removeItem("authTokenDisplay"),
      <Redirect to="/logout" />
    }
  }
}

function* onGetTypes({ roomId }) {
  try {
    const response = yield call(getTypes, roomId)
    yield put(getTypesSuccess(response))
  } catch (error) {
    yield put(getTypesFail(error))
    if (error?.response?.status == 401) {
      localStorage.removeItem("authUser")
      localStorage.removeItem("authTokenDisplay"),
      <Redirect to="/logout" />
    }
  }
}

function* onAttachementUser({ message }) {
  try {
    yield put(togglePageLoader(true))
    const response = yield call(attachementUser, message)
    toastr.success("Mail Sent Successfully")
    setTimeout(() => toastr.clear(), 3000)
    yield put(attachementUserSuccesss(response))
    yield put(togglePageLoader(false))
  } catch (error) {
    toastr.error(error)
    setTimeout(() => toastr.clear(), 3000)
    yield put(attachementUserFails(error))
    yield put(togglePageLoader(false))
  }
}

function* onForgetUser({ username }) {
  try {
    yield put(togglePageLoader(true))
    const response = yield call(forgetUser, username)
    if (response.statusCode == -1) {
      toastr.error(response?.statusMessage)
      yield put(togglePageLoader(false))
    } else {
      yield put(forgetUserSuccesss({ ...username, ...response }))
      toastr.success("Mail Sent Successfully")
      yield put(togglePageLoader(false))
    }
  } catch (error) {
    toastr.error(error)
    setTimeout(() => toastr.clear(), 3000)
    yield put(togglePageLoader(false))
    yield put(forgetUserFails(error))
  }
}
// reset token condition check
function* onTokenUser({ id }) {
  try {
    const response = yield call(tokenUser, id)
    yield put(tokenUserSuccesss(response))
  } catch (error) {
    toastr.error(error)
    setTimeout(() => toastr.clear(), 3000)
    yield put(tokenUserFails(error))
  }
}
function* onResetUser({ payload: { data, history } }) {
  try {
    const response = yield call(resetUser, data)
    toastr.success("Password Changed Successfully")
    setTimeout(() => toastr.clear(), 3000)
    yield put(resetUserSuccesss({ response }))
    history.push("/login")
  } catch (error) {
    toastr.error(error)
    setTimeout(() => toastr.clear(), 3000)
    yield put(resetUserFails(error))
  }
}

function* deleteUserAttc({ id }) {
  try {
    const response = yield call(delUserAttcs, id)
    yield put(delUserAttcSuccesss({ ...response, ...id }))
  } catch (error) {
    toastr.error(error)
    yield put(delUserAttcFails(error))
  }
}

function* siteSaga() {
  yield takeEvery(GET_USER, onGetUser)
  yield takeEvery(POST_ADD_USER, onAddUser)
  yield takeEvery(POST_UPDATE_USER, updateUser)
  yield takeEvery(POST_UPDATE_USERS, updateUsers)
  yield takeEvery(POST_DELETE_USER, deleteUser)
  // Get roles and type in database
  yield takeEvery(GET_ROLES, onGetRoles)
  yield takeEvery(GET_TYPES, onGetTypes)
  // Attachement user
  yield takeEvery(POST_ATTACHEMENT_USER, onAttachementUser)
  // Forget Password
  yield takeEvery(POST_FORGET_USER, onForgetUser)
  // reset password
  yield takeEvery(POST_RESET_USER, onResetUser)
  yield takeEvery(POST_TOKEN_USER, onTokenUser)
  //delete user attachment
  yield takeEvery(POST_DELETE_ATTC_USER, deleteUserAttc)
}

export default siteSaga
