// import React, { Fragment, useState, useEffect } from "react"
// import PropTypes from "prop-types"
// import {
//   useTable,
//   useGlobalFilter,
//   useAsyncDebounce,
//   useSortBy,
//   useFilters,
//   useExpanded,
//   usePagination,
// } from "react-table"
// import { Table, Row, Col, Button, Input } from "reactstrap"
// import { Filter, DefaultColumnFilter, Filters } from "./filters"

// // Define a default UI for filtering
// function GlobalFilters({
//   preGlobalFilteredRows,
//   globalFilter,
//   setGlobalFilter,
// }) {
//   const count = preGlobalFilteredRows.length
//   const [value, setValue] = React.useState(globalFilter)
//   const onChange = useAsyncDebounce(value => {
//     setGlobalFilter(value || undefined)
//   }, 200)

//   return (
//     <Col sm={4}>
//       <div className="search-box me-2 mb-2 d-inline-block">
//         <div className="position-relative">
//           <label htmlFor="search-bar-0" className="search-label">
//             <span id="search-bar-0-label" className="sr-only">
//               Search this table
//             </span>
//             <input
//               onChange={e => {
//                 setValue(e.target.value)
//                 onChange(e.target.value)
//               }}
//               id="search-bar-0"
//               type="text"
//               className="form-control"
//               placeholder={`${count} records...`}
//               value={value || ""}
//             />
//           </label>
//           <i className="bx bx-search-alt search-icon"></i>
//         </div>
//       </div>
//     </Col>
//   )
// }
// // custom Filters
// function GlobalFilter({ column, setGlobalFilter }) {
//   const count = column.preFilteredRows.length
//   const [value, setValue] = useState()
//   const onChange = useAsyncDebounce(value => {
//     setGlobalFilter(value || undefined)
//   }, 200)
//   const removeValues = () => {
//     setValue("")
//     setGlobalFilter("")
//   }
//   return (
//     <Col sm={4}>
//       <div className="search-box me-2 mb-2 d-inline-block">
//         <div className="position-relative">
//           <label htmlFor="search-bar-0" className="search-label">
//             <span id="search-bar-0-label" className="sr-only">
//               Search this table
//             </span>
//             <input
//               onChange={e => {
//                 setValue(e.target.value)
//                 onChange(e.target.value)
//               }}
//               id="search-bar-0"
//               type="text"
//               className="form-control"
//               // placeholder={`${count} records...`}
//               placeholder={``}
//               value={value || ""}
//             />
//           </label>
//           <i className="bx bx-search-alt search-icon"></i>
//           <button
//             type="button"
//             className="close"
//             aria-label="Close"
//             style={{ marginTop: "-2px" }}
//             onClick={removeValues}
//           >
//             <span aria-hidden="true">&times;</span>
//           </button>
//         </div>
//       </div>
//     </Col>
//   )
// }
// // contrtols width in mobile view
// var widths
// function redirectMobileHandler() {
//   const width = Math.max(document.clientWidth || 0, window.innerWidth || 0)
//   widths = width < 600
// }

// window.onload = redirectMobileHandler()
// window.onresize = () => redirectMobileHandler()
// const TableContainer = ({
//   columns,
//   data,
//   isGlobalFilter,
//   buttonname,
//   isAddOptions,
//   handleOrSiteClicks,
//   customPageSize,
//   className,
//   handleRowClick,
// }) => {
//   const [windowWidth, setWindowWidth] = useState(window.innerWidth)
//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     page,
//     prepareRow,
//     canPreviousPage,
//     canNextPage,
//     pageOptions,
//     pageCount,
//     gotoPage,
//     nextPage,
//     previousPage,
//     setPageSize,
//     state,
//     preGlobalFilteredRows,
//     setGlobalFilter,
//     customPageSizeOptions,
//     state: { pageIndex, pageSize },
//   } = useTable(
//     {
//       columns,
//       data,
//       defaultColumn: {
//         Filter: windowWidth < 600 ? GlobalFilter : DefaultColumnFilter,
//       },
//       initialState: {
//         pageIndex: 0,
//         pageSize: customPageSize,
//         sortBy: [
//           {
//             desc: true,
//           },
//         ],
//       },
//     },
//     useGlobalFilter,
//     useFilters,
//     useSortBy,
//     useExpanded,
//     usePagination
//   )

//   useEffect(() => {
//     const handleWindowResize = () => {
//       setWindowWidth(window.innerWidth)
//     }
//     window.addEventListener("resize", handleWindowResize)
//     return () => {
//       window.removeEventListener("resize", handleWindowResize)
//     }
//   })

//   const generateSortingIndicator = column => {
//     return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
//   }

//   const onChangeInSelect = event => {
//     setPageSize(Number(event.target.value))
//   }

//   const onChangeInInput = event => {
//     const page = event.target.value ? Number(event.target.value) - 1 : 0
//     gotoPage(page)
//   }
//   return (
//     <Fragment>
//       <Row className="mb-2">
//         {isAddOptions && (
//           <Col sm="12">
//             <div className="text-sm-end">
//               <Button
//                 type="button"
//                 color="primary"
//                 className="btn-rounded mb-2 me-2"
//                 onClick={handleOrSiteClicks}
//               >
//                 <i className="mdi mdi-plus me-1" />
//                 {buttonname}
//               </Button>
//             </div>
//           </Col>
//         )}
//         <Col sm="12">
//           <div className="mobile_Search">
//             {headerGroups.map(headerGroup => (
//               <>
//                 <div
//                   key={headerGroup.headers.id}
//                   className="mb-2"
//                   {...headerGroup.headers[0]?.getSortByToggleProps()}
//                 ></div>
//                 {windowWidth < 600 ? (
//                   <>
//                     <GlobalFilter
//                       column={headerGroup.headers[0]}
//                       setGlobalFilter={setGlobalFilter}
//                     />
//                   </>
//                 ) : (
//                   <></>
//                 )}
//                 {windowWidth > 601 ? (
//                   <>
//                     <Filters column={headerGroup.headers[0]} />
//                   </>
//                 ) : (
//                   <></>
//                 )}
//               </>
//             ))}
//           </div>
//         </Col>
//       </Row>

//       <div className="table-responsive react-table mb-2">
//         <Table bordered hover {...getTableProps()} className={className}>
//           <thead className="table-light table-nowrap">
//             {headerGroups.map(headerGroup => (
//               <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
//                 {headerGroup.headers.map(column => (
//                   <th key={column.id}>
//                     <div className="mb-2" {...column.getSortByToggleProps()}>
//                       {column.render("Header")}
//                       {generateSortingIndicator(column)}
//                     </div>
//                     <Filter column={column} />
//                   </th>
//                 ))}
//               </tr>
//             ))}
//           </thead>

//           <tbody {...getTableBodyProps()}>
//             {page.map(row => {
//               prepareRow(row)
//               return (
//                 <Fragment key={row.getRowProps().key}>
//                   <tr
//                     onClick={() =>
//                       handleRowClick && handleRowClick(row.original)
//                     }
//                   >
//                     {row.cells.map(cell => {
//                       return (
//                         <td key={cell.id} {...cell.getCellProps()}>
//                           {cell.render("Cell")}
//                         </td>
//                       )
//                     })}
//                   </tr>
//                 </Fragment>
//               )
//             })}
//             {page.length == 0 && (
//               <tr>
//                 <td className="text-center" colSpan={4}>
//                   Sorry, There are no data to display
//                 </td>
//               </tr>
//             )}
//           </tbody>
//         </Table>
//       </div>
//       <Row className="justify-content-md-end justify-content-center align-items-center">
//         <Col sm={customPageSizeOptions ? 2 : "auto"}>
//           <select
//             className="form-select"
//             value={pageSize}
//             onChange={onChangeInSelect}
//           >
//             {[10, 20, 30, 40, 50].map(pageSize => (
//               <option key={pageSize} value={pageSize}>
//                 {pageSize}
//               </option>
//             ))}
//           </select>
//         </Col>
//         <Col sm={customPageSizeOptions ? 2 : "auto"}>
//           <div style={{ padding: "10px 0" }}>
//             Showing {" "}
//             {pageSize < preGlobalFilteredRows.length ? pageSize : preGlobalFilteredRows.length}{" "} of {preGlobalFilteredRows.length } records
//           </div>
//         </Col>
//         <Col className="col-md-auto">
//           <div className="d-flex gap-1">
//             <Button
//               color="primary"
//               onClick={() => gotoPage(0)}
//               disabled={!canPreviousPage}
//             >
//               {"<<"}
//             </Button>
//             <Button
//               color="primary"
//               onClick={previousPage}
//               disabled={!canPreviousPage}
//             >
//               {"<"}
//             </Button>
//           </div>
//         </Col>
//         <Col className="col-md-auto d-none d-md-block">
//           Page{" "}
//           <strong>
//             {pageIndex + 1} of{" "}
//             {pageOptions.length == 0 ? 1 : pageOptions.length}
//           </strong>
//         </Col>
//         <Col className="col-md-auto">
//           <Input
//             type="number"
//             className="page_aligns"
//             min={1}
//             max={pageOptions.length}
//             // defaultValue={pageIndex + 1}
//             value={pageIndex + 1}
//             onChange={onChangeInInput}
//           />
//         </Col>

//         <Col className="col-md-auto">
//           <div className="d-flex gap-1">
//             <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
//               {">"}
//             </Button>
//             <Button
//               color="primary"
//               onClick={() => gotoPage(pageCount - 1)}
//               disabled={!canNextPage}
//             >
//               {">>"}
//             </Button>
//           </div>
//         </Col>
//       </Row>
//     </Fragment>
//   )
// }

// TableContainer.propTypes = {
//   preGlobalFilteredRows: PropTypes.any,
// }

// export default TableContainer

import React, { Fragment, useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"
import { Table, Row, Col, Button, Input } from "reactstrap"
import { Filter, DefaultColumnFilter, Filters } from "./filters"

// custom Filters
function GlobalFilter({ column, setGlobalFilter }) {
  const count = column.preFilteredRows.length
  const [value, setValue] = useState()
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)
  const removeValues = () => {
    setValue("")
    setGlobalFilter("")
  }
  return (
    <Col sm={4}>
      <div className="search-box me-2 mb-2 d-inline-block">
        <div className="position-relative">
          <label htmlFor="search-bar-0" className="search-label">
            <span id="search-bar-0-label" className="sr-only">
              Search this table
            </span>
            <input
              onChange={e => {
                setValue(e.target.value)
                onChange(e.target.value)
              }}
              id="search-bar-0"
              type="text"
              className="form-control"
              // placeholder={`${count} records...`}
              placeholder={``}
              value={value || ""}
            />
          </label>
          <i className="bx bx-search-alt search-icon"></i>
          <button
            type="button"
            className="close"
            aria-label="Close"
            style={{ marginTop: "-2px" }}
            onClick={removeValues}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    </Col>
  )
}
// contrtols width in mobile view
var widths
function redirectMobileHandler() {
  const width = Math.max(document.clientWidth || 0, window.innerWidth || 0)
  widths = width < 600
}

window.onload = redirectMobileHandler()
window.onresize = () => redirectMobileHandler()

const TableContainer = ({
  columns,
  onHeaderClick,
  customPageSize,
  customPageSizeOptions,
  data,
  buttonname,
  isAddOptions,
  handleOrSiteClicks,
  className,
  handleRowClick,
  totalCount,
  pageindex,
  handlePageValue,
  RowClick,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      onHeaderClick: Function,
      // defaultColumn: {
      //   Filter: windowWidth < 600 ? GlobalFilter : DefaultColumnFilter,
      // },
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: pageindex,
        pageSize: customPageSize,
        // sortBy: [
        //   {
        //     desc: true,
        //   },
        // ],
        
      },
      manualPagination: true,
      manualSortBy: true,
      pageCount: Math.ceil(totalCount / customPageSize),
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener("resize", handleWindowResize)
    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  })

  const generateSortingIndicator = column => {
    // return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
    handlePageValue({
      pageSize: Number(event.target.value),
      pageNumber: 0,
      // noChange: true,
    })
  }
  const handlenext = data => {
    
    nextPage(data)
    handlePageValue({
      pageSize: pageSize,
      pageNumber: pageIndex + 1,
    })
  }
  const handleprev = () => {
    previousPage()
    handlePageValue({
      pageSize: pageSize,
      pageNumber: pageIndex - 1,
    })
  }
  const handlestartpage = () => {
    gotoPage(0)
    handlePageValue({
      pageSize: pageSize,
      pageNumber: 0,
    })
  }
  const handlelastpage = () => {
    gotoPage(pageCount - 1)
    handlePageValue({
      pageSize: pageSize,
      pageNumber: Math.ceil(totalCount / pageSize) - 1,
    })
  }
  const onChangeInInput = event => {
    if (pageCount - 1 >= event.target.value) {
      const page = event.target.value > 0 ? Number(event.target.value) - 1 : 0
      gotoPage(page)
      handlePageValue({
        pageSize: pageSize,
        pageNumber: page,
      })
    }
  }
  const getSelectedRowwValues = selectedRow => {
    if (RowClick == true) {
      handleRowClick({ ...selectedRow.original })
    }
  }
  return (
    <Fragment>
      <Row className="mb-2">
        {isAddOptions && (
          <Col sm="12">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn-rounded mb-2 me-2"
                onClick={handleOrSiteClicks}
              >
                <i className="mdi mdi-plus me-1" />
                {buttonname}
              </Button>
            </div>
          </Col>
        )}
        <Col sm="12">
          <div className="mobile_Search">
            {headerGroups.map(headerGroup => (
              <>
                <div
                  key={headerGroup.headers.id}
                  className="mb-2"
                  {...headerGroup.headers[0]?.getSortByToggleProps()}
                ></div>
                {windowWidth < 600 ? (
                  <>
                    <GlobalFilter
                      column={headerGroup.headers[0]}
                      setGlobalFilter={setGlobalFilter}
                    />
                  </>
                ) : (
                  <></>
                )}
                {windowWidth > 601 ? (
                  <>
                    <Filters column={headerGroup.headers[0]} />
                  </>
                ) : (
                  <></>
                )}
              </>
            ))}
          </div>
        </Col>
      </Row>

      <div className="table-responsive react-table mb-2">
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    key={column.id}
                    onClick={() =>
                      onHeaderClick(column, pageSize, pageIndex)
                    }
                  >
                    <div className="mb-2" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                    <Filter column={column} />
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr
                    // onClick={() =>
                    //   handleRowClick && handleRowClick(row.original)
                    // }
                    onClick={e => {
                      {
                        ;(e.target.cellIndex || e.target.cellIndex === 0) &&
                          getSelectedRowwValues(row)
                      }
                    }}
                  >
                    {row.cells.map(cell => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                </Fragment>
              )
            })}
            {page.length == 0 && (
              <tr>
                <td className="text-center" colSpan={columns?.length}>
                  Sorry, There are no data to display
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Col sm={customPageSizeOptions ? 2 : "auto"}>
          <select
            className="form-select"
            value={pageSize}
            onChange={onChangeInSelect}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col sm={customPageSizeOptions ? 2 : "auto"}>
          <div style={{ padding: "10px 0" }}>
            Showing{" "}
            {pageSize < preGlobalFilteredRows.length
              ? pageSize
              : preGlobalFilteredRows.length}{" "}
            of {preGlobalFilteredRows.length} records
          </div>
        </Col>
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={handlestartpage}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              onClick={handleprev}
              disabled={!canPreviousPage}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page{" "}
          <strong>
            {pageindex + 1} of {Math.ceil(totalCount / pageSize)}
          </strong>
        </Col>
        <Col className="col-md-auto">
          <Input
            type="number"
            className="page_aligns"
            value={pageindex + 1}
            // defaultValue={pageIndex + 1}
            onChange={onChangeInInput}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={handlenext}
              disabled={
                !canNextPage ||
                pageIndex >= Math.ceil(totalCount / pageSize) - 1
              }
            >
              {">"}
            </Button>
            <Button
              color="primary"
              onClick={handlelastpage}
              disabled={
                !canNextPage ||
                pageIndex >= Math.ceil(totalCount / pageSize) - 1
              }
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  )
}

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default TableContainer
