import React, { useEffect, useMemo, useState, useRef } from "react"
import PropTypes from "prop-types"
import TableContainer from "../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"
import * as moment from "moment"
import { ChangeHeading } from "../../store/dashboard/actions"
import DeleteModal from "../../components/Common/DeleteModal"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  addSite as onAddSite,
  getSites as onGetSites,
  delSite as deleteSite,
  upSite as updateSites,
  addClock as onAddClock,
  upClock as upAddClock,
  delAttc as deleteAttc,
  getClock as onGetClock,
} from "store/actions"
import toast from "toastr"
//redux
import { useSelector, useDispatch } from "react-redux"
import { BillingName } from "./siteCols"

import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import Dropzone from "react-dropzone"
import SearchForm from "./searchsiteForm"
import { saveAs } from "file-saver"
import axios from "axios"
function SiteDetails() {
  //meta title

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [displays, setDisplays] = useState(false)
  const [order, setOrder] = useState(null)
  const [selectedFilesHazard, setselectedFilesHazard] = useState([])
  const [site, setSite] = useState({})
  const [activeSite, setActiveSite] = useState({})
  const [datas, setDatas] = useState([])
  const [pageSize, setpageSize] = useState(10)
  const [pageindex, setpageindex] = useState(0)
  const [filterSiteName, setFilterSiteName] = useState()
  const [filterSiteManager, setFilterSiteManager] = useState()
  const [filterContactNumber, setFilterContactNumber] = useState()
  const [filterAddress, setFilterAddress] = useState()
  const [sort, setSort] = useState({ direction: "desc", accessor: "Name" })

  // maintain data files data
  const dispatch = useDispatch()
  const {
    sites,
    clocks,
    success,
    upsuccess,
    Attcup,
    createSuccess,
    totalcount,
  } = useSelector(state => ({
    sites: state?.sites?.sites,
    clocks: state.sites?.clock,
    success: state.sites?.success?.statusMessage,
    upsuccess: state.sites?.successs?.statusMessage,
    Attcup: state.sites?.successsAttc,
    createSuccess: state.sites?.createSuccess?.statusMessage,
    totalcount: state.sites?.siteCount,
  }))

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("authTokenDisplay") === "1")
    if (items) {
      setDisplays(items)
    }
    dispatch(ChangeHeading(displays ? "SITES" : "CHECK IN/OUT"))
  }, [displays])

  const [activeSatuss, setActiveSatuss] = useState({})
  useEffect(() => {
    setActiveSatuss(success)
  }, [activeSatuss])

  document.title = displays
    ? "Sites | Premier Plastering"
    : "Check In/ Out| Premier Plastering"

  const [clockInModal, setClockInModal] = useState(displays)

  // validation

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: site?.name || "",
      manager: site?.manager || "",
      contact: site?.contact || "",
      address: site?.address || "",
      risk_Assessments: site?.risk_Assessments || "",
      is_Deleted: site?.is_Deleted || true,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Site Name"),
      manager: Yup.string().required("Please Enter Site Manager"),
      contact: Yup.number()
        .typeError("Must be valid Number")
        .required("Please Enter Contact Number"),
      address: Yup.string().required("Please Enter Address"),
      risk_Assessments: Yup.string().required("Please Enter Site Restrictions"),
    }),

    onSubmit: values => {
      if (isEdit) {
        if (
          site?.sitesAttachments?.length == 0 &&
          selectedFilesHazard.length == 1
        ) {
          toast.error("Please Choose Minimum 2 file.")
        } else if (
          site?.sitesAttachments?.length == 1 &&
          selectedFilesHazard.length == 0
        ) {
          toast.error("Please Choose Minimum 2 file.")
        } else {
          var updatas = new FormData()
          updatas.append("Name", values.name)
          updatas.append("Address", values.address)
          updatas.append("Manager", values.manager)
          updatas.append("Contact", values.contact)
          updatas.append("Risk_Assessments", values.risk_Assessments)
          updatas.append("id", site ? site.id : 0)
          if (selectedFilesHazard.length > 0) {
            selectedFilesHazard.map(selectedsFileHazard => {
              updatas.append("Hazard", selectedsFileHazard)
            })
          } else {
            updatas.append("Hazard", null)
          }
          // update order
  
          // return false
          dispatch(updateSites(updatas, handleResponse))
        }
      } else {
        if (selectedFilesHazard?.length == 0) {
          toast.error("Please Choose Minimum 2 file.")
        } else if (selectedFilesHazard?.length == 1) {
          toast.error("Please Choose Minimum 2 file.")
        } else {
          var datas = new FormData()
          if (selectedFilesHazard.length > 0) {
            selectedFilesHazard.map(selectedFileHazard => {
              datas.append("Hazard", selectedFileHazard)
            })
          } else {
            datas.append("Hazard", null)
          }
          datas.append("Name", values.name)
          datas.append("Address", values.address)
          datas.append("Manager", values.manager)
          datas.append("Contact", values.contact)
          datas.append("Risk_Assessments", values.risk_Assessments)
          datas.append("Created_Date", moment().format())

          dispatch(onAddSite(datas, handleResponse))
        }
      }
    },
  })
  const handleResponse = status => {
    if (status === "SUCCESS") {
      toggle()
      setselectedFilesHazard([])
      validation.resetForm()
    }
  }

  const [address, setAddress] = useState(null)
  const [lat, setLat] = useState(null)

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const { latitude, longitude } = position.coords
        setLat(position.coords)
        fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCFTTtXIqu5jhGSEg4JP9oKPI8vlapTKRc`
        )
          .then(response => response.json())
          .then(data => {
            setAddress(data?.results?.[0]?.formatted_address)
          })
          .catch(error => console.error(error))
      })
    } else {
      console.error("Geolocation is not supported by this browser.")
    }
  }, [])

  function handleAcceptedFilesHazard(files) {
    files?.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFilesHazard([...selectedFilesHazard, ...files])
  }
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  useEffect(() => {
    if (createSuccess === "Site Create Successfully.") {
      dispatch(onGetSites())
    }
  }, [createSuccess])
  const params = {
    pagenumber: pageindex + 1,
    pagesize: pageSize,
    sortKey: sort.accessor,
    sortValue: sort.direction,
    managername: filterSiteManager,
    siteName: filterSiteName,
    contactNo: filterContactNumber,
    address: filterAddress,
  }
  useEffect(() => {
    if (createSuccess === "Site Create Successfully.") {
      dispatch(onGetSites(params))
    }
  }, [createSuccess])
  useEffect(() => {
    dispatch(onGetSites(params))
    dispatch(onGetClock(params))
  }, [dispatch])

  useEffect(() => {
    if (Attcup?.statusMessage === "Site updated Successfully.") {
      dispatch(onGetSites(params))
    }
  }, [Attcup])

  useEffect(() => {
    if (upsuccess === "work Log updated Successfully.") {
      dispatch(onGetSites(params))
    }
  }, [upsuccess])

  useEffect(() => {
    if (success === "Work Log Create Successfully.") {
      dispatch(onGetSites(params))
    }
  }, [success])

  const toggle = () => {
    if (modal) {
      setModal(false)
      validation.resetForm()
      setselectedFilesHazard([])
    } else {
      setModal(true)
    }
  }

  const toggleClockIn = () => {
    setClockInModal(!clockInModal)
  }

  const handleRowClick = arg => {
    const site = arg
    setSite({
      ...site,
    })
    setIsEdit(true)
    toggle()
  }
  const handleFileRemovedata = siteData => {
    const delAttch = {
      id: siteData?.attachments?.id,
      sites_Id: siteData?.sites_Id,
      attachment_Id: siteData?.attachment_Id,
    }

    var sitesAttachments = site?.sitesAttachments?.filter(
      attachment => attachment?.attachments?.id != siteData?.attachments?.id
    )

    setSite({
      ...site,
      sitesAttachments,
    })
    dispatch(deleteAttc(delAttch))
  }
  const clockIns = value => {
    const user_id = JSON.parse(localStorage.getItem("authUser"))
    var clockIn = {
      id: 0,
      clock_In: moment().format(),
      clock_Out: "",
      users_Id: user_id?.user?.id,
      sites_Id: value ? value.id : 0,
      address: "",
      latitude: lat?.latitude,
      longitude: lat?.longitude,
      status: "true",
    }
    dispatch(onAddClock(clockIn))
  }

  const clockIn = () => {
    const user_id = JSON.parse(localStorage.getItem("authUser"))
    var clockIn = {
      id: 0,
      clock_In: moment().format(),
      clock_Out: "",
      users_Id: user_id?.user?.id,
      sites_Id: activeSite ? activeSite.id : 0,
      address: "",
      latitude: lat?.latitude,
      longitude: lat?.longitude,
      status: "true",
    }
    dispatch(onAddClock(clockIn))
    setTimeout(function () {
      setClockInModal(false)
    }, 1000)
  }

  const clockOut = () => {
    const user_id = JSON.parse(localStorage.getItem("authUser"))
    var clockIn = {
      id: activeSite.worklogID,
      clock_In: activeSite?.workersLog?.clock_In,
      clock_Out: moment().format(),
      users_Id: user_id?.user?.id,
      sites_Id: activeSite ? activeSite.id : 0,
      address: "",
      latitude: lat?.latitude,
      longitude: lat?.longitude,
      status: "false",
    }

    // return false
    dispatch(upAddClock(clockIn))
    setTimeout(function () {
      setClockInModal(false)
    }, 1000)
  }

  const clockOuts = value => {
    const user_id = JSON.parse(localStorage.getItem("authUser"))
    var clockIn = {
      id: value.worklogID,
      clock_In: value?.workersLog?.clock_In,
      clock_Out: moment().format(),
      users_Id: user_id?.user?.id,
      sites_Id: value ? value.id : 0,
      address: "",
      latitude: lat?.latitude,
      longitude: lat?.longitude,
      status: "false",
    }

    // return false
    dispatch(upAddClock(clockIn))
  }
  //delete order
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = order => {
    setOrder(order)
    setDeleteModal(true)
  }

  const handleDeleteOrder = () => {
    if (order.id) {
      dispatch(deleteSite(order.id))
      setDeleteModal(false)
    }
  }

  const handleSiteClicks = () => {
    setIsEdit(false)
    setSite({})
    toggle()
  }

  const handleFileRemoveHazard = index => {
    setselectedFilesHazard(selectedFilesHazard.filter((_, i) => i !== index))
  }

  const showClockInModal = data => {
    if (!displays) {
      setActiveSite(data)
      toggleClockIn()
    }
  }

  const handleDownloadClick = files => {
    files.forEach(file => {
      // saveAs(file?.attachments?.fileURL, file?.attachments?.fileName)
      axios({
        url: file?.attachments?.fileURL,
        method: "GET",
        responseType: "blob",
      }).then(response => {
        saveAs(response.data, file?.attachments?.fileName)
      })
    })
  }

  const myContentRef = useRef(null)

  useEffect(() => {
    const content = myContentRef.current
    if (content) {
      content.scrollTop = content.scrollHeight - content.clientHeight
    }
  }, [myContentRef.current])

  var mergeSites = sites?.map(item => ({
    id: item.id,
    name: item.name,
    address: item.address,
    manager: item.manager,
    contact: item.contact,
    worker_statusClock:
      (item?.workersLog?.status == "true" && "Check In") ||
      (item?.workersLog?.status == "false" && "Check Out"),
    risk_Assessments: item.risk_Assessments,
    sitesAttachments: item?.sitesAttachments,
    worklogID: item?.workersLog?.id,
  }))

  useEffect(() => {
    setDatas(mergeSites)
  }, [sites])

  const columnHeaderClick = (column, pagesize, pageIndex) => {
    switch (column.sortDirection) {
      case "none":
        setSort({ direction: "asc", accessor: column.sortName })
        handlePageValue({
          pageSize: pagesize,
          pageNumber: pageIndex,
          sortKey: column.sortName,
          sortValue: "asc",
        })
        break
      case "asc":
        setSort({ direction: "desc", accessor: column.sortName })
        handlePageValue({
          pageSize: pagesize,
          pageNumber: pageIndex,
          sortKey: column.sortName,
          sortValue: "desc",
        })
        break
      case "desc":
        setSort({ direction: "asc", accessor: column.sortName })
        handlePageValue({
          pageSize: pagesize,
          pageNumber: pageIndex,
          sortKey: column.sortName,
          sortValue: "asc",
        })
        break
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Site Name",
        accessor: "name",
        disableFilters: true,
        sortName: "Name",
        sortDirection: sort.accessor === "Name" ? sort.direction : "none",
        Cell: cellProps => {
          return (
            <BillingName
              {...cellProps}
              onClick={() => showClockInModal(cellProps.row.original)}
            />
          )
        },
      },
      {
        Header: "Site Manager",
        accessor: "manager",
        sortName: "Manager",
        disableFilters: true,
        sortDirection: sort.accessor === "Manager" ? sort.direction : "none",
        Cell: cellProps => {
          return (
            <BillingName
              {...cellProps}
              onClick={() => showClockInModal(cellProps.row.original)}
            />
          )
        },
      },
      {
        Header: "Contact No",
        accessor: "contact",
        sortName: "Contact",
        disableFilters: true,
        sortDirection: sort.accessor === "Contact" ? sort.direction : "none",
        Cell: cellProps => {
          return (
            <BillingName
              {...cellProps}
              onClick={() => showClockInModal(cellProps.row.original)}
            />
          )
        },
      },
      {
        Header: "Address",
        accessor: "address",
        disableFilters: true,
        sortName: "Address",
        sortDirection: sort.accessor === "Address" ? sort.direction : "none",
        Cell: cellProps => {
          return (
            <BillingName
              {...cellProps}
              onClick={() => showClockInModal(cellProps.row.original)}
            />
          )
        },
      },
      // clockInModal
      {
        Header: " ",
        Cell: displays
          ? cellProps => {
              return (
                <>
                  {
                    <div className="d-flex gap-3 absolute">
                      <UncontrolledDropdown>
                        <DropdownToggle href="#" className="card-drop" tag="i">
                          <i className="mdi mdi-dots-horizontal font-size-18" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem
                            href="#"
                            onClick={() =>
                              handleRowClick(cellProps.row.original)
                            }
                          >
                            <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
                            Edit
                          </DropdownItem>
                          <DropdownItem
                            href="#"
                            onClick={() =>
                              onClickDelete(cellProps.row.original)
                            }
                          >
                            <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
                            Delete
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  }
                </>
              )
            }
          : cellProps => {
              return <></>
            },
      },
      {
        Header: displays ? "    " : "Site Restriction",
        accessor: displays ? "    " : "risk_Assessments",
        disableFilters: true,
      },
      {
        Header: displays ? "  " : "Check In/Out",
        accessor: displays ? "   " : "worker_statusClock",
        disableFilters: true,
        Cell: !displays
          ? cellProps => {
              var work_status = cellProps?.row?.original?.worker_statusClock
              return (
                <>
                  <button
                    type="submit"
                    className="btn btn-success save-user"
                    onClick={() => clockIns(cellProps?.row?.original)}
                    disabled={work_status == "Check In" && true}
                  >
                    Check In
                  </button>

                  {work_status === "Check In" ? (
                    <button
                      type="submit"
                      className="btn btn-danger save-user mx-1"
                      onClick={() => clockOuts(cellProps?.row?.original)}
                      disabled={work_status == "Check Out" && true}
                    >
                      Check Out
                    </button>
                  ) : null}
                </>
              )
            }
          : cellProps => {
              return <></>
            },
      },

      {
        Header: displays ? "  " : "Risk Assessment/Method Statement",
        disableFilters: true,
        Cell: !displays
          ? cellProps => {
              var download_status = cellProps?.row?.original?.sitesAttachments

              return (
                <div className="text-center">
                  {download_status?.length > 0 && (
                    <div onClick={() => handleDownloadClick(download_status)}>
                      <i className="bx bx-download h3 m-0" />
                    </div>
                  )}
                </div>
              )
            }
          : cellProps => {
              return <></>
            },
      },
    ],
    [displays, upsuccess, success, activeSatuss, sort]
  )
  const handlePageValue = value => {
    if (!value.noChange) setpageindex(value.pageNumber)
    setpageSize(value.pageSize)
    dispatch(
      onGetSites({
        ...value,
        pageNumber: value.pageNumber + 1,
        pageSize: pageSize,
        managername: filterSiteManager,
        siteName: filterSiteName || "",
        contactNo: filterContactNumber || "",
        address: filterAddress || "",
      })
    )
  }

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(
      onGetSites({
        pageNumber: pageindex + 1,
        pageSize: pageSize,
        managername: filterSiteManager,
        siteName: filterSiteName || "",
        contactNo: filterContactNumber || "",
        address: filterAddress || "",
      })
    )
  }

  const removeValuesSiteName = () => {
    setFilterSiteName("")
    if (filterSiteName?.length > 0) {
      dispatch(
        onGetSites({
          pagenumber: pageindex + 1,
          pageSize: pageSize,
          manager: filterSiteManager || "",
          name: "",
          contact: filterContactNumber || "",
          address: filterAddress || "",
          sortKey: sort.accessor,
          sortValue: sort.direction,
        })
      )
    } else {
      dispatch(
        onGetSites({
          pagenumber: pageindex + 1,
          pageSize: pageSize,
          sortKey: sort.accessor,
          sortValue: sort.direction,
          manager: "",
          contact: "",
          address: "",
        })
      )
    }
  }

  const removeValuesManagerName = () => {
    setFilterSiteManager("")
    if (filterSiteManager?.length > 0) {
      dispatch(
        onGetSites({
          pagenumber: pageindex + 1,
          pageSize: pageSize,
          managername: "",
          siteName: filterSiteName || "",
          contactNo: filterContactNumber || "",
          address: filterAddress || "",
          sortKey: sort.accessor,
          sortValue: sort.direction,
        })
      )
    } else {
      dispatch(
        onGetSites({
          pagenumber: pageindex + 1,
          pageSize: pageSize,
          siteName: "",
          contactNo: "",
          address: "",
          sortKey: sort.accessor,
          sortValue: sort.direction,
        })
      )
    }
  }
  const removeValuesContactNumber = () => {
    setFilterContactNumber("")
    if (filterContactNumber?.length > 0) {
      dispatch(
        onGetSites({
          pagenumber: pageindex + 1,
          pageSize: pageSize,
          managername: filterSiteManager || "",
          siteName: filterSiteName || "",
          contactNo: "",
          address: filterAddress || "",
          sortKey: sort.accessor,
          sortValue: sort.direction,
        })
      )
    } else {
      dispatch(
        onGetSites({
          pagenumber: pageindex + 1,
          pageSize: pageSize,
          siteName: "",
          managername: "",
          address: "",
          sortKey: sort.accessor,
          sortValue: sort.direction,
        })
      )
    }
  }

  const removeValuesAddress = () => {
    setFilterAddress("")
    if (filterAddress?.length > 0) {
      dispatch(
        onGetSites({
          pagenumber: pageindex + 1,
          pageSize: pageSize,
          managername: filterSiteManager || "",
          siteName: filterSiteName || "",
          contactNo: filterContactNumber || "",
          address: "",
          sortKey: sort.accessor,
          sortValue: sort.direction,
        })
      )
    } else {
      dispatch(
        onGetSites({
          pagenumber: pageindex + 1,
          pageSize: pageSize,
          managername: "",
          siteName: "",
          contactNo: "",
          sortKey: sort.accessor,
          sortValue: sort.direction,
        })
      )
    }
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Dashboard"
            breadcrumbItem={displays ? "Sites" : "Check In/Out"}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <SearchForm
                    setFilterSiteName={setFilterSiteName}
                    filterSiteName={filterSiteName}
                    setFilterSiteManager={setFilterSiteManager}
                    filterSiteManager={filterSiteManager}
                    setFilterContactNumber={setFilterContactNumber}
                    filterContactNumber={filterContactNumber}
                    setFilterAddress={setFilterAddress}
                    filterAddress={filterAddress}
                    handleOrSiteClicks={handleSiteClicks}
                    removeValuesSiteName={removeValuesSiteName}
                    removeValuesManagerName={removeValuesManagerName}
                    removeValuesContactNumber={removeValuesContactNumber}
                    removeValuesAddress={removeValuesAddress}
                    handleSubmit={handleSubmit}
                    isAddOptions={displays}
                  />
                  <TableContainer
                    handleRowClick={showClockInModal}
                    RowClick={true}
                    columns={columns}
                    data={datas || []}
                    buttonname="Add New Sites"
                    isGlobalFilter={true}
                    // isAddOptions={displays}
                    isAddOptions={false}
                    hidesiteButton={false}
                    handleOrSiteClicks={handleSiteClicks}
                    customPageSize={10}
                    className={`custom-header-css ${
                      !displays ? "cursor_points" : ""
                    } `}
                    totalCount={totalcount || 0}
                    pageindex={pageindex}
                    setpageindex={setpageindex}
                    handlePageValue={handlePageValue}
                    onHeaderClick={columnHeaderClick}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Site" : "Add Sites"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row form>
                  <Col className="col-6 form_align">
                    <div className="mb-3">
                      <Label className="form-label">
                        Sites Name <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        name="name"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                          validation.touched.name && validation.errors.name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.name}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">
                        Site Manager <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        name="manager"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.manager || ""}
                        invalid={
                          validation.touched.manager &&
                          validation.errors.manager
                            ? true
                            : false
                        }
                      />
                      {validation.touched.manager &&
                      validation.errors.manager ? (
                        <FormFeedback type="invalid">
                          {validation.errors.manager}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">
                        Contact No <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        name="contact"
                        type="tel"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.contact || ""}
                        invalid={
                          validation.touched.contact &&
                          validation.errors.contact
                            ? true
                            : false
                        }
                      />
                      {validation.touched.contact &&
                      validation.errors.contact ? (
                        <FormFeedback type="invalid">
                          {validation.errors.contact}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">
                        Address <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        name="address"
                        type="textarea"
                        row="5"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.address || ""}
                        invalid={
                          validation.touched.address &&
                          validation.errors.address
                            ? true
                            : false
                        }
                      />

                      {validation.touched.address &&
                      validation.errors.address ? (
                        <FormFeedback type="invalid">
                          {validation.errors.address}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">
                        Site Restrictions{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        name="risk_Assessments"
                        type="textarea"
                        rows="2"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.risk_Assessments || ""}
                        invalid={
                          validation.touched.risk_Assessments &&
                          validation.errors.risk_Assessments
                            ? true
                            : false
                        }
                      />
                      {validation.touched.risk_Assessments &&
                      validation.errors.risk_Assessments ? (
                        <FormFeedback type="invalid">
                          {validation.errors.risk_Assessments}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col className="col-6 form_align">
                    <div className="mb-1">
                      <Label className="form-label">
                        Risk Assessment/Method Statement
                      </Label>
                    </div>
                    <Form>
                      <Dropzone
                        onDrop={acceptedFiles => {
                          handleAcceptedFilesHazard(acceptedFiles)
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick mt-2"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div
                        className="dropzone-previews my-3"
                        id="file-previews"
                      >
                        {selectedFilesHazard.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col>
                                    <p className="mb-0 text-muted font-weight-bold pr-2">
                                      {f.name}
                                    </p>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                    <button
                                      type="button"
                                      className="btn-close"
                                      aria-label="Close"
                                      style={{
                                        position: "absolute",
                                        right: "10px",
                                        top: 0,
                                        cursor: "pointer",
                                      }}
                                      onClick={() => handleFileRemoveHazard(i)}
                                    ></button>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          )
                        })}

                        {!!isEdit === false ||
                        site?.sitesAttachments?.length == 0 ? (
                          <></>
                        ) : (
                          <div>
                            {site?.sitesAttachments?.map(i => (
                              <div
                                className="files_ui"
                                key={i?.attachments?.id}
                              >
                                <a
                                  key={i?.attachments?.id}
                                  href={i?.attachments?.fileURL}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="files_ui_link"
                                >
                                  {i?.attachments?.fileName}
                                </a>
                                <button
                                  type="button"
                                  className="btn-close"
                                  aria-label="Close"
                                  style={{
                                    position: "absolute",
                                    right: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleFileRemovedata(i)}
                                ></button>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </Form>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
          {/* Clock in / out modal */}
          <Modal
            isOpen={clockInModal}
            toggle={toggleClockIn}
            size="lg"
            className="center_modal"
          >
            <ModalHeader toggle={toggleClockIn} tag="h4">
              Check In/Out
            </ModalHeader>
            <ModalBody>
              <div
                ref={myContentRef}
                style={{ height: "auto", maxHeight: "70vh", overflowY: "auto" }}
                className="mb-2 simplebar-content-wrapper"
              >
                <table className="table">
                  <tbody>
                    <tr>
                      <th>Name:</th>
                      <td>{activeSite?.name}</td>
                    </tr>
                    <tr>
                      <th>Manager:</th>
                      <td>{activeSite?.manager}</td>
                    </tr>
                    <tr>
                      <th>Address:</th>
                      <td>{activeSite?.address}</td>
                    </tr>
                    <tr>
                      <th>Risk Assessments:</th>
                      <td>{activeSite?.risk_Assessments}</td>
                    </tr>
                    <tr>
                      <th>Hazard:</th>
                      <td>
                        {activeSite?.sitesAttachments?.map(i => (
                          <a
                            key={i?.attachments?.id}
                            href={i?.attachments?.fileURL}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="merge_data">
                              {i?.sitesAttachments == 0
                                ? "No Hazard Data"
                                : i?.attachments?.fileName}
                            </span>
                          </a>
                        ))}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="text-start">
                {activeSite?.worker_statusClock !== "Check In" ? (
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                    onClick={clockIn}
                  >
                    Check In
                  </button>
                ) : null}

                {activeSite?.worker_statusClock === "Check In" ? (
                  <button
                    type="submit"
                    className="btn btn-secondary save-user mx-2 "
                    onClick={clockOut}
                  >
                    Check Out
                  </button>
                ) : null}
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}
SiteDetails.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default SiteDetails
