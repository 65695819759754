import React from "react"
import InputMask from "react-input-mask"
import { Col, Row, Label } from "reactstrap"

export class Cards extends React.Component {
  constructor(props) {
    super(props)
  }

  handleExpiryChange = e => {
    let date = e.target.value
    if (date.slice(0, 2) > 12) {
      e.target.value = 12
    }
    if (date.slice(0, 2) < 13) {
      this.setState({
        day: date.slice(0, 2),
        month: date.slice(2),
      })
    }
    if (e.target.value === "") {
      this.setState({
        day: "•• ",
        month: " •• ",
      })
    }
    this.props.setExpiryDates(e.target.value)
  }

  render() {
    return (
      <Row>
        <Col className="col-8 form_align">
          <Label className="form-label">CSCS Card Number</Label>
          <InputMask
            type="number"
            className="form-control input-color"
            id="card_Number"
            // mask="9999 9999 9999 9999"
            // placeholder="0000 0000 0000 0000"
            placeholder="XXXXXXXXXXX"
            value={this.props.cardNumber}
            onChange={this.props.onChange}
          />
        </Col>

        <Col className="col-4 form_align">
          <Label className="form-label">Valid Till</Label>
          <InputMask
            name="expiryDate"
            type="text"
            mask="99/99"
            className="mobile_expiry form-control input-color"
            placeholder="MM/YY"
            onChange={this.handleExpiryChange}
            value={this.props.expiryDates || this.props.expiryDate}
          />
        </Col>
      </Row>
    )
  }
}

export default Cards
