import {
    API_SUCCESS,
    API_FAIL,
    GET_CHARTS_DATA,
    CHANGE_HEADING,
    USER_ROLE,
    GET_SOS_SUCCESS,
    GET_SOS_FAIL,
    ADD_SOS_SUCCESS,
    ADD_SOS_FAIL,
    UPDATE_SOS_SUCCESS,
    UPDATE_SOS_FAIL,
    TOGGLE_MODAL
} from "./actionTypes";

const INIT_STATE = {
    chartsData: [],
    SosNumber:0,
    toggleModal:''
};

const Dashboard = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_SUCCESS:
            switch (action.payload.actionType) {
                case GET_CHARTS_DATA:
                    return {
                        ...state,
                        chartsData: action.payload.data
                    };
                    
                default:
                    return state;
            }
        case API_FAIL:
            switch (action.payload.actionType) {
                case GET_CHARTS_DATA:
                    return {
                        ...state,
                        chartsDataError: action.payload.error
                    };


                default:
                    return state;
            }
            case CHANGE_HEADING:
                return {
                    ...state,
                    NavBarTitle: action.payload,
                }
            case USER_ROLE:
                return {
                    ...state,
                    Role: action.payload,
                }
        case GET_SOS_SUCCESS:
            action.payload.setting = action.payload.setting[0];
            return {
                ...state,
                SosNumber: action.payload,
            }
        case GET_SOS_FAIL:
            return {
                ...state,
                SosError: action.payload,
            }
        case ADD_SOS_SUCCESS:
                return {
                    ...state,
                    SosNumber: action.payload,
                    toggleModal:false
                }
        case ADD_SOS_FAIL:
            return {
                ...state,
                SosError: action.payload,
            }
            
        case UPDATE_SOS_SUCCESS:
                return {
                    ...state,
                    SosNumber: action.payload,
                    toggleModal:false
                }
        case UPDATE_SOS_FAIL:
            return {
                ...state,
                SosError: action.payload,
            }

        case TOGGLE_MODAL:
            return {
                ...state,
                toggleModal:''
            }
       
        default:
            return state;
    }
}


export default Dashboard;