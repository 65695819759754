import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
const getToken = () => {
  return JSON.parse(localStorage.getItem("authUser") || "{}")?.user?.accessToken
}
// const token = JSON.parse(localStorage.getItem('authUser')||'{}')?.user?.accessToken;

//apply base url for axios
// const API_URL = "http://103.104.124.147:9033/";
const API_URL = "https://api.premsafe.uk/";

const axiosApi = axios.create({
  baseURL: API_URL,
})

// axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;

axiosApi.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${getToken()}`
  return config
})

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function patch(url, data, config = {}) {
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then(response => response.data)
}
export async function dels(url, data, config = {
  headers: {
    "Content-Type": "application/json-patch+json",
  },
}) {

  return await axiosApi
    .delete(url, {data})
    .then(response => response.data)
}
export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
export async function attchpost(
  url,
  data,
  config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }
) {
  return axiosApi.post(url, data, { ...config }).then(response => response.data)
}
export async function attchpatch(
  url,
  data,
  config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }
) {
  return axiosApi.patch(url, data, { ...config }).then(response => response.data)
}