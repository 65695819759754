/** Get Dashboard Chart data */
export const API_SUCCESS = "API_SUCCESS";
export const API_FAIL = "API_FAIL";
export const GET_CHARTS_DATA = "GET_CHARTS_DATA";
export const CHANGE_HEADING = "CHANGE_HEADING"
export const USER_ROLE = "USER_ROLE"
/*Get SOS */
export const GET_SOS = "GET_SOS";
export const GET_SOS_SUCCESS = "GET_SOS_SUCCESS";
export const GET_SOS_FAIL = "GET_SOS_FAIL";

/*Add SOS */
export const ADD_SOS = "ADD_SOS";
export const ADD_SOS_SUCCESS = "ADD_SOS_SUCCESS";
export const ADD_SOS_FAIL = "ADD_SOS_FAIL";

/*Update SOS */
export const UPDATE_SOS = "UPDATE_SOS";
export const UPDATE_SOS_SUCCESS = "UPDATE_SOS_SUCCESS";
export const UPDATE_SOS_FAIL = "UPDATE_SOS_FAIL";

export const TOGGLE_MODAL = "TOGGLE_MODAL"
