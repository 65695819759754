import React, { useState, useEffect } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import { ChangeHeading } from "../../store/dashboard/actions"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Form,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import Dropzone from "react-dropzone"
import { useFormik } from "formik"
import * as Yup from "yup"
import * as moment from "moment"
import {
  upUser as updateUser,
  getRoles as onGetRoles,
  getUser as onGetUser,
  delUserAttc as deleteUserAttc,
} from "store/actions"
import InputMask from "react-input-mask"
import Cards from "./Cards"

const UserProfile = () => {
  const [selectedFiles, setSelectedFiles] = useState([])
  const [order, setOrder] = useState({})
  const dispatch = useDispatch()
  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setSelectedFiles(files)
  }
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const { rolesType, users, success } = useSelector(state => ({
    rolesType: state.users?.roles?.roles,
    users: state.users?.users,
    success: state.users?.success?.statusMessage,
  }))
  const params = {
    pagenumber: 1,
    pagesize: 150,
  }
  useEffect(() => {
    dispatch(onGetRoles())
    dispatch(onGetUser(params))
  }, [dispatch])

  useEffect(() => {
    if (success === "Update Successfully.") {
      dispatch(onGetUser(params))
    }
  }, [success])

  useEffect(() => {
    const Obj = JSON.parse(localStorage.getItem("authUser"))
    const filter = users.filter(data => data.id == Obj.user.id)
    setOrder(filter[0])
  }, [users])

  const disablePastDate = () => {
    const today = new Date()
    const dd = String(today.getDate() + 1).padStart(2, "0")
    const mm = String(today.getMonth() + 1).padStart(2, "0") //January is 0!
    const yyyy = today.getFullYear()
    return yyyy + "-" + mm + "-" + dd
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (order && order?.name) || "",
      number: (order && order?.number) || "",
      username: (order && order?.username) || "",
      emergency_Name: (order && order?.emergency_Name) || "",
      address: (order && order?.address) || "",
      emergency_Contact: (order && order?.emergency_Contact) || "",
      company_Name: (order && order?.company_Name) || "",
      expiryDate: (order && order?.expiryDate) || "",
      card_Number: (order && order?.card_Number) || "",
      dates: (order && order?.dob) || "",
      job_role: order?.userRoles?.[0]?.role_Id || "",
      expireDate: (order && order?.expireDate) || "",
      ses_card_qualification:
        order?.userAttachments?.[0]?.attachments?.fileName,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      address: Yup.string().required("Please Enter Address"),
      emergency_Contact: Yup.string().required("Please Enter Emergency Number"),
      number: Yup.string().required("Enter Your Contact Number"),
      username: Yup.string()
        .email("Enter valid Email address")
        .max(255)
        .required("Please Enter Email"),
      job_role: Yup.string().required("Please Enter Job Role"),
      dates: Yup.string().required("Please Enter Date"),
      emergency_Name: Yup.string().required("Please Emergency Name"),
    }),
    onSubmit: values => {
      const obj = [
        {
          Id: order?.userRoles?.[0]?.id,
          Role_Id: JSON.parse(values.job_role),
          User_Id: order ? order?.id : 0,
        },
      ]
      const jionobj = [
        {
          Id: order?.userTypes?.[0]?.id,
          Type_Id: order?.userTypes?.[0]?.type_Id,
          User_Id: order ? order?.id : 0,
        },
      ]
      var datas = new FormData()
      if (selectedFiles.length > 0) {
        selectedFiles.map(selectedFile => {
          datas.append("SES_Card_Qualification", selectedFile)
        })
      } else {
        datas.append("SES_Card_Qualification", null)
      }
      datas.append("Username", values.username)
      datas.append("Card_Number", values.card_Number)
      datas.append("Name", values.name)
      datas.append("Address", values.address)
      datas.append("ExpiryDate", expiryDates)
      datas.append("Number", values.number)
      datas.append("Emergency_Contact", values.emergency_Contact)
      datas.append("Company_Name", values.company_Name)
      datas.append("UserRoles", JSON.stringify(obj))
      datas.append("UserTypes", JSON.stringify(jionobj))
      datas.append("Emergency_Name", values.emergency_Name)
      datas.append("DOB", moment(new Date(values.dates)).format("YYYY-MM-DD"))
      datas.append("Id", order ? order.id : 0)
      // update order
      dispatch(updateUser(datas, handleResponse))
      setSelectedFiles([])
    },
  })

  const handleResponse = status => {
    if (status === "SUCCESS") {
      console.log("")
    }
  }
  //meta title
  document.title = "Plastering | Users"

  useEffect(() => {
    dispatch(ChangeHeading("PROFILE"))
  }, [dispatch])

  const handleFileRemoves = index => {
    setSelectedFiles(selectedFiles.filter((_, i) => i !== index))
  }

  const handleFileRemovedatas = userDatas => {
    const delAttchs = {
      id: userDatas.attachments?.id,
      user_Id: userDatas.user_Id,
      attachment_Id: userDatas.attachment_Id,
    }

    let userAttachments = order.userAttachments.filter(
      attachment => attachment.attachments?.id != userDatas.attachments?.id
    )

    setOrder({
      ...order,
      userAttachments,
    })
    // return false
    dispatch(deleteUserAttc(delAttchs))
  }

  const [expiryDates, setExpiryDates] =useState(null)
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Dashboard" breadcrumbItem="Profile" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row form>
                      <Col className="col-6 form_align">
                        <div className="mb-3">
                          <Label className="form-label">
                            Name <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input
                            name="name"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">
                            Email <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input
                            name="username"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.username || ""}
                            invalid={
                              validation.touched.username &&
                              validation.errors.username
                                ? true
                                : false
                            }
                          />
                          {validation.touched.username &&
                          validation.errors.username ? (
                            <FormFeedback type="invalid">
                              {validation.errors.username}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">
                            Company Name (Optional)
                          </Label>
                          <Input
                            name="company_Name"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.company_Name || ""}
                          />
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">
                            D.O.B <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input
                            name="dates"
                            type="date"
                            format="YYYY MM DD"
                            max={disablePastDate()}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            // value={validation.values.dates || ""}
                            value={
                              moment(new Date(validation.values.dates)).format(
                                "YYYY-MM-DD"
                              ) || ""
                            }
                            invalid={
                              validation.touched.dates &&
                              validation.errors.dates
                                ? true
                                : false
                            }
                          />
                          {validation.touched.dates &&
                          validation.errors.dates ? (
                            <FormFeedback type="invalid">
                              {validation.errors.dates}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">
                            Job Role <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input
                            id="job_role"
                            type="select"
                            name="job_role"
                            value={validation.values.job_role || ""}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            invalid={
                              validation.touched.job_role &&
                              validation.errors.job_role
                                ? true
                                : false
                            }
                          >
                            <option key={`optionVal_empty`} value="">
                              Please Select Roles
                            </option>
                            {(rolesType || [])?.map((option, i) => (
                              <option key={`optionVal_${i}`} value={option?.id}>
                                {option?.name}
                              </option>
                            ))}
                          </Input>

                          {validation.touched.job_role &&
                          validation.errors.job_role ? (
                            <FormFeedback type="invalid">
                              {validation.errors.job_role}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">
                            Contact Number{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input
                            name="number"
                            type="number"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.number || ""}
                            invalid={
                              validation.touched.number &&
                              validation.errors.number
                                ? true
                                : false
                            }
                          />
                          {validation.touched.number &&
                          validation.errors.number ? (
                            <FormFeedback type="invalid">
                              {validation.errors.number}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">
                            Emergency Contact{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input
                            name="emergency_Name"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.emergency_Name || ""}
                            invalid={
                              validation.touched.emergency_Name &&
                              validation.errors.emergency_Name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.emergency_Name &&
                          validation.errors.emergency_Name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.emergency_Name}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">
                            Emergency Contact Number{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input
                            name="emergency_Contact"
                            type="tel"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.emergency_Contact || ""}
                            invalid={
                              validation.touched.emergency_Contact &&
                              validation.errors.emergency_Contact
                                ? true
                                : false
                            }
                          />
                          {validation.touched.emergency_Contact &&
                          validation.errors.emergency_Contact ? (
                            <FormFeedback type="invalid">
                              {validation.errors.emergency_Contact}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col className="col-6 form_align">
                        <div className="mb-3">
                          <Label className="form-label">
                            Address <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input
                            name="address"
                            type="textarea"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.address || ""}
                            invalid={
                              validation.touched.address &&
                              validation.errors.address
                                ? true
                                : false
                            }
                          />
                          {validation.touched.address &&
                          validation.errors.address ? (
                            <FormFeedback type="invalid">
                              {validation.errors.address}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="">
                          <Label className="form-label">CSCS Card</Label>
                        </div>
                        <Form>
                          <Dropzone
                            onDrop={acceptedFiles => {
                              handleAcceptedFiles(acceptedFiles)
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick mt-2"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>Drop files here or click to upload.</h4>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div
                            className="dropzone-previews my-3"
                            id="file-previews"
                          >
                            {selectedFiles.map((f, i) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={i + "-file"}
                                >
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col>
                                        <p className="mb-0 text-muted font-weight-bold">
                                          {f.name}
                                        </p>
                                        <p className="mb-0">
                                          <strong>{f.formattedSize}</strong>
                                        </p>
                                        <button
                                          type="button"
                                          className="btn-close"
                                          aria-label="Close"
                                          style={{
                                            position: "absolute",
                                            right: "10px",
                                            top: 0,
                                            cursor: "pointer",
                                          }}
                                          onClick={() => handleFileRemoves(i)}
                                        ></button>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              )
                            })}
                            {order?.userAttachments?.length == 0 ? null : (
                              <div>
                                {order?.userAttachments?.map(i => (
                                  <div
                                    className="files_ui"
                                    key={i?.attachments?.id}
                                  >
                                    <a
                                      key={i?.attachments?.id}
                                      href={i?.attachments?.fileURL}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="files_ui_link"
                                    >
                                      {i?.attachments?.fileName}
                                    </a>
                                    <button
                                      type="button"
                                      className="btn-close"
                                      aria-label="Close"
                                      style={{
                                        position: "absolute",
                                        right: "20px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => handleFileRemovedatas(i)}
                                    ></button>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>

                          <div className="mb-3">
                            <Cards
                              expiryDate={validation.values.expiryDate || ""}
                              cardNumber={validation.values.card_Number || ""}
                              onChange ={validation.handleChange}
                              setExpiryDates={setExpiryDates}
                              expiryDates={expiryDates}
                            />
                            
                          </div>
                        </Form>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <div className="text-end text_alignUser">
                          <button
                            type="submit"
                            className="btn btn-success save-user"
                          >
                            Save
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UserProfile
