import {
  PAGE_LOADER,
} from "./actionTypes";

export const togglePageLoader = message => {
  return {
    type: PAGE_LOADER,
    payload: message,
  };
};
