import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

import { useSelector, useDispatch } from "react-redux"

import { ChangeHeading, 
         LoginUser,
         addClock as onAddClock,
         upClock as upAddClock,
         getCheckIn } from "../../store/actions"
import { Link } from "react-router-dom"
import * as moment from "moment"
import AddSosModal from "../../components/AddSosModal"

const DashBoard = props => {
  const dispatch = useDispatch();
  const [checkInStatus, setCheckInStatus] = useState(false);
  const user_id = JSON.parse(localStorage.getItem("authUser"))
  const { check_in_details } = useSelector(state => ({
    check_in_details:state.sites?.workersLog,
  }))

  useEffect(()=>{
   if(check_in_details?.status === "true"){
    setCheckInStatus(true)
   }else{
    setCheckInStatus(false)
   }
  },[check_in_details])

  const [openAddSos,setOpenAddSos] = useState(false)
  const { sos_number } = useSelector(state => ({
    sos_number: state.Dashboard?.SosNumber
  }))
  const reports = [
    {
      icon: "bx bx-user-circle",
      title: "Users",
      value: "$ 16.2",
      badgeValue: "0%",
      color: "warning",
      desc: "From previous period",
      path: "/users",
    },
   /*  {
      icon: "bx bx-heart-circle",
      title: "Sites",
      value: "$ 28,452",
      badgeValue: "+ 0.2%",
      color: "success",
      desc: "From previous period",
      path: "/sites",
    }, */
    {
      icon: "bx bx-pencil",
      title: "Profile",
      value: "1,452",
      badgeValue: "+ 0.2%",
      color: "success",
      desc: "From previous period",
      path: "/profile",
    },
    {
      icon: "bx bx-alarm-add",
      title: "Check In/Out",
      value: "$ 16.2",
      badgeValue: "0%",
      color: "warning",
      desc: "From previous period",
      path: "/checkinOut",
    },
   /*  {
      icon: "bx bx-comment-add",
      title: "Notification",
      value: "$ 16.2",
      badgeValue: "0%",
      color: "warning",
      desc: "From previous period",
      path: "/push-notification",
    }, */
    {
      icon: "bx bx-comment-add",
      title: "SOS/Emergency",
      value: "$ 16.2",
      badgeValue: "0%",
      color: "warning",
      desc: "From previous period",
      path: "#",
    },
    // {
    //     icon: "bx bx-paperclip",
    //     title: "Attachments",
    //     value: "$ 16.2",
    //     badgeValue: "0%",
    //     color: "warning",
    //     desc: "From previous period",
    //     path: '/attachment'
    // },
  ]
  const reportsUser = [
    {
      icon: "bx bx-pencil",
      title: "Profile",
      value: "1,452",
      badgeValue: "+ 0.2%",
      color: "success",
      desc: "From previous period",
      path: "/user-profile",
    },
    // {
    //     icon: "bx bx-alarm-add",
    //     title: "Hazard",
    //     value: "$ 16.2",
    //     badgeValue: "0%",
    //     color: "warning",
    //     desc: "From previous period",
    //     path: '/checkinOut'
    // },
    {
      icon: "bx bx-paperclip",
      title: "Attachments",
      value: "$ 16.2",
      badgeValue: "0%",
      color: "warning",
      desc: "From previous period",
      path: "/attachment",
    },
    {
      icon: "bx bx-alarm-add",
      title: "Check In/Out",
      value: "$ 28,452",
      badgeValue: "+ 0.2%",
      color: "success",
      desc: "From previous period",
      path: "#",
    },
    {
      icon: "bx bx-comment-add",
      title: "SOS/Emergency",
      value: "$ 16.2",
      badgeValue: "0%",
      color: "warning",
      desc: "From previous period",
      path: "#",
    }
  ]

  const [displays, setDisplays] = useState(false)

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("authTokenDisplay") === "1")
    if (items) {
      setDisplays(items)
    }
    dispatch(ChangeHeading("DASHBOARD"))
    // dispatch(LoginUser(items))
    
    dispatch(getCheckIn(user_id?.user?.id))
  }, [])

  const clockInOut = (type) => {  
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const { latitude, longitude } = position.coords

        fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCFTTtXIqu5jhGSEg4JP9oKPI8vlapTKRc`
        )
          .then(response => response.json())
          .then(data => {
            //setAddress(data?.results?.[0]?.formatted_address)
          })
          .catch(error => console.error(error))

        const clockIn = {
          id: 0,
          clock_In: moment().format(),
          clock_Out: "",
          users_Id: user_id?.user?.id,
          sites_Id: '',
          address: "",
          status: "true",
        }
        if(type === "clockin"){
          clockIn.latitude = latitude
          clockIn.longitude = longitude
          clockIn.created_Date = moment().format();
          dispatch(onAddClock(clockIn))
        }else{
          clockIn.checkout_latitude = latitude
          clockIn.checkout_longitude = longitude
          clockIn.id =  check_in_details.id;
          clockIn.created_Date = check_in_details.created_Date;
          clockIn.clock_In = check_in_details.clock_In;
          clockIn.clock_Out =  moment().format();
          clockIn.status = "false"
          dispatch(upAddClock(clockIn))
        }
      })
    } else {
      console.error("Geolocation is not supported by this browser.")
    }
   
  } 

  const openAddSosModal =(type) =>{
    if(type === "SOS/Emergency"){
      setOpenAddSos(true);
    }
  }

  const handleDialClick = (type) => {
    if(type === "SOS/Emergency"){
      const phonenumber = sos_number?.setting?.sos_Number;
      window.location.href = `tel:${phonenumber}`;
    }
  };

  return (
    <React.Fragment>
       <AddSosModal
        show={openAddSos}
        onCloseClick={() => setOpenAddSos(false)}
      />
      <div className="page-content">
        <Container>
          {/* <Header title="DashBoard" breadcrumbItem="Hello Frankie" /> */}
          <div className="text-center">
            <Row>
              {(!displays ? reportsUser : reports).map((report, key) => (
                <Col sm="4" key={key}>
                  <Link to={report.path}>
                    <Card className="mini-stats-wid" onClick={()=>{
                      if(!displays){
                        handleDialClick(report.title)
                      }else{
                        openAddSosModal(report.title)
                      }
                    }}>
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon mx-auto mb-3">
                              <span className="avatar-title rounded-circle bg-primary">
                                <i
                                  className={report.icon + " font-size-24"}
                                ></i>
                              </span>
                            </div>
                            <h4 className="mb-0 mobile_fonts">{report.title}</h4>
                          </div>
                        </div>
                        {(report.title === "Check In/Out" &&  !displays) &&
                            <button
                              type="submit"
                              className={!checkInStatus?"btn btn-success save-user mt-4":"btn btn-danger save-user mt-4"}
                              onClick={() => {!checkInStatus?clockInOut("clockin"):clockInOut("clockout")}}
                            >
                              {!checkInStatus?"Check In":"Check out"}
                            </button>
                         }
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
              ))}
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}
DashBoard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(DashBoard)
