import { post } from "./api_helper";
import * as url from "./url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
const postRegister = data => post(url.REGISTER_API_URL, data);

// Login Method
const postLogin = data => post(url.LOGIN_API_URL, data);

// postForgetPwd
const postForgetPwd = data => post(url.FORGOT_API_URL, data);

export {
  getLoggedInUser,
  isUserAuthenticated,
  postRegister,
  postLogin,
  postForgetPwd
};