import { useEffect,useState } from "react"
import PropTypes from "prop-types"
import React from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"
import { withRouter, Link, useLocation, useParams } from "react-router-dom"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
// action
import {
  forgetUser as onForgetUser,
  tokenUser as onTokenUser,
  resetUser as onResetUser,
} from "store/actions"
import profile from "assets/images/PP/logo.png"
import logo from "assets/images/PP/PP-icon.png"



const ResetPassword = props => {
  //meta title
  document.title = "Reset Password | Premier Plastering"
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const location = useLocation()
  const { token } = useSelector(state => state.users)
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const myParam = queryParams.get("AccessToken")

    setTimeout(() => {
      dispatch(onTokenUser(myParam))
      setLoading(false)
    }, 500)
  }, [])
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please Enter Your Password").min(6, "Password must be at least 6 characters"),
    }),
    onSubmit: values => {
      const resetpwd = {
        password: values.password,
        username: token.userName,
      }

      dispatch(onResetUser(resetpwd, props.history))
    },
  })
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-secondary bg-soft">
                  <Row>
                    <Col xs={6}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Reset Password in to continue to tsis.</p>
                      </div>
                    </Col>
                    <Col className="col-6 align-self-center">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="50"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  {loading ? (
                    <div className="d-flex justify-content-center">
                      <div className="spinner-border " role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {token?.statusCode === 1 ? (
                    <div className="p-2">
                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        <div className="mb-3">
                          <Label className="form-label">Password</Label>
                          <Input
                            name="password"
                            className="form-control"
                            placeholder="Enter Password"
                            type="password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <Row className="mb-3">
                          <Col className="text-end">
                            <button
                              className="btn btn-primary w-md "
                              type="submit"
                            >
                              Submit
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  ) : (
                    <Alert color="danger" style={{ marginTop: "13px" }}>
                      {token?.statusMessage}
                    </Alert>
                  )}
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} tsis. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Premier
                  Plastering
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ResetPassword.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ResetPassword)
