import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { GET_CHARTS_DATA,
        CHANGE_HEADING,
        USER_ROLE,
        GET_SOS,
        ADD_SOS,
        UPDATE_SOS } from "./actionTypes";
import { apiSuccess, 
         apiFail,
         apititleSuccess,
         apititleFail,
         getSOSNumberSuccess,
         getSOSNumberFail,
         addSOSNumberSuccess,
         addSOSNumberFail,
         updateSOSNumberSuccess,
         updateSOSNumberFail
} from "./actions";

//Include Both Helper File with needed methods
import {
    getWeeklyData,
    getYearlyData,
    getMonthlyData,
    getNavtitle,
    getUserRole,
    getSOS,
    addSOS,
    updateSOS
}
    from "../../helpers/fakebackend_helper";
import toastr from "toastr"
import { togglePageLoader } from "store/actions"

function* getChartsData({ payload: periodType }) {
    try {
        var response;
        if (periodType == "monthly") {
            response = yield call(getWeeklyData, periodType);
        }
        if (periodType == "yearly") {
            response = yield call(getYearlyData, periodType);
        }
        if (periodType == "weekly") {
            response = yield call(getMonthlyData, periodType);
        }

        yield put(apiSuccess(GET_CHARTS_DATA, response));
    } catch (error) {
        yield put(apiFail(GET_CHARTS_DATA, error));
    }
}
function* headingChange({ payload: title }) {
    try {
       var response;
       response = yield call(title);
    } catch (error) {
    }
 
}
function* userRole({ payload: role }) {
    try {
       var response;
       response = yield call(role);
    } catch (error) {
    }
 
}

function* getSOSNumber() {
    try {
      const response = yield call(getSOS)
      yield put(getSOSNumberSuccess(response))
    } catch (error) {
      yield put(getSOSNumberFail(error))
    }
}

function* addSOSNumber({ payload: data }) {
  yield put(togglePageLoader(true))
    try {
      const response = yield call(addSOS,data)
      yield put(addSOSNumberSuccess(response))
      yield put(togglePageLoader(false))
      toastr.success("Added SOS successfully")
    } catch (error) {
      yield put(togglePageLoader(false))
      yield put(addSOSNumberFail(error))
      toastr.error("Failed to Add")
    }
}

function* updateSOSNumber({ payload: data }) {
    yield put(togglePageLoader(true))
    try {
      const response = yield call(updateSOS,data)
      yield put(updateSOSNumberSuccess(response))
      yield put(togglePageLoader(false))
      toastr.success("Updated SOS successfully")
    } catch (error) {
      yield put(togglePageLoader(false))
      yield put(updateSOSNumberFail(error))
      toastr.success("Failed to Update")
    }
}

export function* watchGetChartsData() {
    yield takeEvery(GET_CHARTS_DATA, getChartsData);
}
export function* watchNavTitle() {
    yield takeEvery(CHANGE_HEADING, headingChange);
  }
export function* watchUserRole() {
    yield takeEvery(USER_ROLE, userRole);
  }

export function* watchSOS() {
  yield takeEvery(GET_SOS, getSOSNumber);
  yield takeEvery(ADD_SOS, addSOSNumber);
  yield takeEvery(UPDATE_SOS, updateSOSNumber);
}


function* dashboardSaga() {
    yield all([fork(watchGetChartsData),
        fork(watchNavTitle),
        fork(watchUserRole),
        fork(watchSOS)
    ]);
}

export default dashboardSaga;
