import {
  POST_ADD_NOTIFICATION,
  POST_ADD_NOTIFICATION_FAIL,
  POST_ADD_NOTIFICATION_SUCCESS,
} from "./actionTypes"

export const addNotify = message => ({
  type: POST_ADD_NOTIFICATION,
  message,
})

export const addNotifySuccess = message => ({
  type: POST_ADD_NOTIFICATION_SUCCESS,
  payload: message,
})

export const addNotifyFail = error => ({
  type: POST_ADD_NOTIFICATION_FAIL,
  payload: error,
})
