import React from "react"
import { Link } from "react-router-dom"
import * as moment from "moment"
import { Badge } from "reactstrap"

const formateDate = (date, format) => {
  const dateFormat = format ? format : "LLL"
  const date1 = moment(new Date(date)).format(dateFormat)
  return date1
}

const BillingName = cell => {
  return cell.value ? cell.value : ""
}

const LocationName = cell => {
  return cell.value ? (
    <div>
      <div>{cell.value}</div>
      <div>Address: {cell.column.sortName==="clock_Out"?cell.row.original.checkout_latitude:cell.row.original.latitude}, {cell.column.sortName==="clock_Out"?cell.row.original.checkout_longitude:cell.row.original.longitude}</div>
    </div>
  ) : (
    ""
  )
}

const Date = cell => {
  return cell.value ? moment(cell.value).format("MMMM Do YYYY, h:mm:ss a") : ""
}

const Total = cell => {
  return cell.value ? moment(cell.value).format("MMMM Do YYYY, h:mm:ss a") : ""
}

const PaymentStatus = cell => {
  return cell.value ? cell.value : ""
}
const PaymentMethod = cell => {
  return cell.value ? cell.value : ""
}
export {
  // OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
  LocationName,
}
