import React from 'react';
import { Link } from 'react-router-dom';
import * as moment from "moment";
import { Badge } from 'reactstrap';

const formateDate = (date, format) => {
    const dateFormat = format ? format : "DD MMM Y";
    const date1 = moment(new Date(date)).format(dateFormat);
    return date1;
};
const toLowerCase1 = str => {
    return (
      str === "" || str === undefined ? "" : str.toLowerCase()
    );
  };

// const OrderId = (cell) => {
//     return (
//         <Link to="#" className="text-body fw-bold">{cell.value ? cell.value : ''}</Link>
//     );
// };

const BillingName = (cell) => {
    return <p role='button' onClick={cell.onClick}>{cell.value ? cell.value : ''}</p>
};

const Status = (cell) => {
    return cell.value ? cell.value : '';
};

const Total = (cell) => {
    return cell.value ? cell.value : '';
};

const PaymentStatus = (cell) => {
    return cell.value ? cell.value : '';
};
const PaymentMethod = (cell) => {
    return cell.value ? cell.value : '';
};
export {
    // OrderId,
    BillingName,
    // Date,
    Total,
    PaymentStatus,
    PaymentMethod
};