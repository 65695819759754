import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import TableContainer from "../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"
import { ChangeHeading } from "../../store/dashboard/actions"
import DeleteModal from "../../components/Common/DeleteModal"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  getUser as onGetUser,
  addUser as onAddUser,
  delUser as deleteUser,
  upUsers as updateUsers,
  getTypes as onGetTypes,
} from "store/actions"
//redux
import { useDispatch, useSelector } from "react-redux"

import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import SearchForm from "./searchForm"

function Users() {
  //meta title
  document.title = "Users | Premier Plastering"

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [user, setUser] = useState({})
  const [order, setOrder] = useState([])
  const [pageSize, setpageSize] = useState(10)
  const [pageindex, setpageindex] = useState(0)
  const [filterUserEmail, setFilterUserEmail] = useState(null)
  const [filterName, setFilterName] = useState(null)
  const [filterUserType, setFilterUserType] = useState(null)
  const [sort, setSort] = useState({ direction: "asc", accessor: "Name" })

  const dispatch = useDispatch()

  const { users, type, successadd, successs, totalcount } = useSelector(
    state => ({
      users: state.users.users,
      type: state.users.type.type,
      successadd: state.users.successadd?.statusMessage,
      successs: state.users.successs?.statusMessage,
      totalcount: state?.users?.userCount,
    })
  )

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: user.name || "",
      username: user.username || "",
      usertype: user?.userTypes?.[0]?.type_Id,
      password: user.password || "",
      is_Deleted: user.is_Deleted || true,
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      username: Yup.string()
        .email("Enter valid Email address")
        .max(255)
        .required("Please Enter Email"),
      usertype: Yup.string().required("Please Select User Type"),
      password: Yup.string()
        .required("Please Enter Password")
        .min(6, "Password must be at least 6 characters"),
    }),

    onSubmit: values => {
      if (isEdit) {
        const obj = [
          {
            Id: user?.userTypes?.[0]?.id,
            Type_Id: JSON.parse(values.usertype),
            User_Id: user ? user?.id : 0,
          },
        ]
        var datas = new FormData()
        datas.append("Id", user ? user.id : 0)
        datas.append("Name", values.name)
        datas.append("Username", values.username)
        datas.append("Password", values.password)
        datas.append("UserTypes", JSON.stringify(obj))
        // update order
        // return false
        dispatch(updateUsers(datas, handleResponse))
        // validation.resetForm()
      } else {
        const max = users.reduce(function (prev, current) {
          return prev.id > current.id ? prev : current
        })
        const newUsers = {
          name: values.name,
          username: values.username,
          password: values.password,
          isDeleted: false,
          id: max.id + 1,
          userTypes: [
            {
              user_Id: max.id + 1,
              type_Id: values.usertype,
            },
          ],
        }
        // save new order
        dispatch(onAddUser(newUsers, handleResponse))
      }
    },
  })
  const handleResponse = status => {
    if (status === "SUCCESS") {
      toggle()
      validation.resetForm()
    }
  }
  const params = {
    pagenumber: pageindex + 1,
    pagesize: pageSize,
    sortKey: sort.accessor,
    sortValue: sort.direction,
  }
  // Get Type and roles
  useEffect(() => {
    dispatch(onGetUser(params))
    dispatch(onGetTypes())
  }, [])

  useEffect(() => {
    if (successadd === "User Create Successfully.") {
      dispatch(onGetUser(params))
    }
  }, [successadd])
  useEffect(() => {
    if (successs === "Update Successfully.") {
      dispatch(onGetUser(params))
    }
  }, [successs])

  const toggle = () => {
    if (modal) {
      setModal(false)
      validation.resetForm()
    } else {
      setModal(true)
    }
  }

  useEffect(() => {
    dispatch(ChangeHeading("USERS"))
  }, [])

  const handleRowClick = arg => {
    const user = arg
    setUser({
      ...user,
    })
    setIsEdit(true)
    toggle()
  }

  //delete order
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = order => {
    setOrder(order)
    setDeleteModal(true)
  }

  const handleDeleteOrder = () => {
    if (order.id) {
      dispatch(deleteUser(order.id))
      setDeleteModal(false)
    }
  }

  const handleSiteClicks = () => {
    setIsEdit(false)
    setUser({})
    toggle()
  }

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(
      onGetUser({
        pagenumber: 1,
        pagesize: pageSize,
        username: filterUserEmail,
        name: filterName,
        userType: filterUserType,
      })
    )
  }

  const handlePageValue = value => {
    if (!value.noChange) setpageindex(value.pageNumber)
    setpageSize(value.pageSize)
    dispatch(
      onGetUser({
        ...value,
        pageNumber: value.pageNumber + 1,
      })
    )
  }

  const removeValues = () => {
    setFilterUserEmail("")
    if (filterName?.length > 0) {
      dispatch(
        onGetUser({
          pageNumber: 1,
          pageSize: pageSize,
          userEmail: "",
          Name: filterName || "",
          usertype: filterUserType || "",
        })
      )
    } else {
      dispatch(
        onGetUser({
          pageNumber: 1,
          pageSize: pageSize,
          userEmail: "",
          Name: "",
          usertype: "",
        })
      )
    }
  }
  const removeValuesName = () => {
    setFilterName("")
    if (filterName?.length > 0) {
      dispatch(
        onGetUser({
          pageNumber: 1,
          pageSize: pageSize,
          userEmail: filterUserEmail || "",
          firstName: "",
          usertype: filterUserType || "",
        })
      )
    } else {
      dispatch(
        onGetUser({
          pageNumber: 1,
          pageSize: pageSize,
          userEmail: "",
          Name: "",
          usertype: "",
        })
      )
    }
  }

  const removeValuesUserType = () => {
    setFilterUserType("")
    if (filterUserType?.length > 0) {
      dispatch(
        onGetUser({
          pageNumber: 1,
          pageSize: pageSize,
          userEmail: filterUserEmail || "",
          Name: filterName || "",
          usertype: "",
        })
      )
    } else {
      dispatch(
        onGetUser({
          pageNumber: 1,
          pageSize: pageSize,
          userEmail: "",
          userName: "",
        })
      )
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        disableFilters: true,
        dataType: "string",
        sortName: "Name",
        sortDirection: sort.accessor === "Name" ? sort.direction : "none",
      },
      {
        Header: "Email",
        accessor: "username",
        disableFilters: true,
        dataType: "string",
        sortName: "UserName",
        sortDirection: sort.accessor === "UserName" ? sort.direction : "none",
      },
      {
        Header: "User Type",
        accessor: "userTypes[0].types.name",
        disableFilters: true,
        dataType: "string",
        sortName: "UserType",
        sortDirection: sort.accessor === "UserType" ? sort.direction : "none",
      },

      {
        Header: " ",
        Cell: cellProps => {
          return (
            <div
              className="d-flex gap-3 absolute"
              style={{ cursor: "Pointer" }}
            >
              <UncontrolledDropdown>
                <DropdownToggle href="#" className="card-drop" tag="i">
                  <i className="mdi mdi-dots-horizontal font-size-18" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem
                    href="#"
                    onClick={() => handleRowClick(cellProps.row.original)}
                  >
                    <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    href="#"
                    onClick={() => onClickDelete(cellProps.row.original)}
                  >
                    <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          )
        },
      },
    ],
    [type, sort]
  )

  const columnHeaderClick = (column, pagesize, pageIndex) => {

    switch (column.sortDirection) {
      case "none":
        
        setSort({ direction: "asc", accessor: column.sortName })
        handlePageValue({
          pageSize: pageSize,
          pageNumber: pageIndex,
          sortKey: column.sortName,
          sortValue: "asc",
        })
        break
      case "asc":
        
        setSort({ direction: "desc", accessor: column.sortName })
        handlePageValue({
          pageSize: pageSize,
          pageNumber: pageIndex,

          sortKey: column.sortName,
          sortValue: "desc",
        })
        break
      case "desc":
        
        setSort({ direction: "asc", accessor: column.sortName })
        handlePageValue({
          pageSize: pageSize,
          pageNumber: pageIndex,
          sortKey: column.sortName,
          sortValue: "asc",
        })
        break
    }
  }
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Dashboard" breadcrumbItem="Users" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <SearchForm
                    setFilterUserEmail={setFilterUserEmail}
                    filterUserEmail={filterUserEmail}
                    setFilterUserType={setFilterUserType}
                    filterUserType={filterUserType}
                    handleSubmit={handleSubmit}
                    removeValues={removeValues}
                    removeValuesUserType={removeValuesUserType}
                    removeValuesName={removeValuesName}
                    setFilterName={setFilterName}
                    filterName={filterName}
                    handleOrSiteClicks={handleSiteClicks}
                  />
                  <TableContainer
                    handlePageValue={handlePageValue}
                    RowClick={true}
                    totalCount={totalcount || 0}
                    columns={columns}
                    data={users || []}
                    // buttonname="Add New Users"
                    isAddOptions={false}
                    handleOrSiteClicks={handleSiteClicks}
                    customPageSize={10}
                    pageindex={pageindex}
                    setpageindex={setpageindex}
                    handleRowClick={handleRowClick}
                    onHeaderClick={columnHeaderClick}
                    className="custom-header-css table-responsive-stack"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            isOpen={modal}
            toggle={toggle}
            size="lg"
            className="center_modal"
          >
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Users" : "Add Users"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row form>
                  <Col className="col-6 form_align">
                    <div className="mb-3">
                      <Label className="form-label">
                        Name <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        name="name"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                          validation.touched.name && validation.errors.name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.name}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">
                        Email <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        name="username"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.username || ""}
                        invalid={
                          validation.touched.username &&
                          validation.errors.username
                            ? true
                            : false
                        }
                      />
                      {validation.touched.username &&
                      validation.errors.username ? (
                        <FormFeedback type="invalid">
                          {validation.errors.username}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col className="col-6 form_align">
                    <div className="mb-3">
                      <Label className="form-label">
                        User Type <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        id="usertype"
                        type="select"
                        name="usertype"
                        placeholder="Select User Type"
                        value={validation.values.usertype || ""}
                        onBlur={validation.handleBlur}
                        onChange={validation.handleChange}
                        invalid={
                          validation.touched.usertype &&
                          validation.errors.usertype
                            ? true
                            : false
                        }
                      >
                        <option key={`optionVal_empty`} value="">
                          Please Select Type
                        </option>
                        {(type || [])?.map((option, i) => (
                          <option key={`optionVal_${i}`} value={option?.id}>
                            {option?.name}
                          </option>
                        ))}
                      </Input>
                      {validation.touched.usertype &&
                      validation.errors.usertype ? (
                        <FormFeedback type="invalid">
                          {validation.errors.usertype}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">
                        Password <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        name="password"
                        type="Password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.password || ""}
                        invalid={
                          validation.touched.password &&
                          validation.errors.password
                            ? true
                            : false
                        }
                      />

                      {validation.touched.password &&
                      validation.errors.password ? (
                        <FormFeedback type="invalid">
                          {validation.errors.password}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}
Users.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Users
