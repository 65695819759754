import React from "react"
import { Col, Input, Row, Form } from "reactstrap"

const SearchForm = props => {
  const {
    filterCheckIn,
    setFilterCheckIn,
    filterCheckOut,
    setFilterCheckOut,
    filterWorkerSiteName,
    setFilterWorkerSiteName,
    removeValuesrCheckIn,
    removeValuesrCheckOut,
    removeValuesrWorkerSiteName,
    handleSubmit,
  } = props
  return (
    <Form onSubmit={handleSubmit}>
      <Row className="mb-2">
        <Col className=" justify-content-start">
          <Input
            type="text"
            className="mb-2"
            placeholder="Search for Workers Name"
            value={filterWorkerSiteName || ""}
            onChange={e => {
              setFilterWorkerSiteName(e.target.value || undefined)
            }}
          />
          <button
            type="button"
            className="close"
            aria-label="Close"
            style={{ marginTop: "-3px", marginRight: "10px" }}
            onClick={removeValuesrWorkerSiteName}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Col>

        {/* <Col className=" justify-content-start">
          <Input
            type="text"
            className="mb-2"
            placeholder="Search for Check In"
            value={filterCheckIn || ""}
            onChange={e => {
              setFilterCheckIn(e.target.value || undefined)
            }}
          />
          <button
            type="button"
            className="close"
            aria-label="Close"
            style={{ marginTop: "-3px", marginRight: "10px" }}
            onClick={removeValuesrCheckIn}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Col>
        <Col className=" justify-content-start">
          <Input
            type="text"
            className="mb-2"
            placeholder="Search for Check Out"
            value={filterCheckOut || ""}
            onChange={e => {
              setFilterCheckOut(e.target.value || undefined)
            }}
          />
          <button
            type="button"
            className="close"
            aria-label="Close"
            style={{ marginTop: "-3px", marginRight: "10px" }}
            onClick={removeValuesrCheckOut}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Col> */}

       
        <Col className="justify-content-start">
          <button
            type="submit"
            className="btn btn-primary save-customer me-2"
            onClick={handleSubmit}
          >
            Search
          </button>
        </Col>
      </Row>
    </Form>
  )
}
export default SearchForm
