import {
  GET_SITES,
  GET_SITES_FAIL,
  GET_SITES_SUCCESS,
  POST_ADD_SITE,
  POST_ADD_SITE_FAIL,
  POST_ADD_SITE_SUCCESS,
  POST_UPDATE_SITE,
  POST_UPDATE_SITE_SUCCESS,
  POST_UPDATE_SITE_FAIL,
  POST_DELETE_SITE,
  POST_DELETE_SITE_SUCCESS,
  POST_DELETE_SITE_FAIL,
  POST_ADD_CLOCK,
  POST_ADD_CLOCK_SUCCESS,
  POST_ADD_CLOCK_FAIL,
  GET_CLOCK,
  GET_CLOCK_FAIL,
  GET_CLOCK_SUCCESS,
  POST_DELETE_ATTC_SUCCESS,
  POST_DELETE_ATTC,
  POST_DELETE_ATTC_FAIL,
  POST_UP_CLOCK_SUCCESS,
  POST_UP_CLOCK_FAIL,
  POST_UP_CLOCK,
  GET_CHECK_IN,
  GET_CHECK_IN_SUCCESS,
  GET_CHECK_IN_FAIL
} from "./actionTypes"

export const getSites = data => ({
  type: GET_SITES,
  payload: data
})

export const getSitesSuccess = chats => ({
  type: GET_SITES_SUCCESS,
  payload: chats,
})

export const getSitesFail = error => ({
  type: GET_SITES_FAIL,
  payload: error,
})

export const addSite = (message,callback) => ({
  type: POST_ADD_SITE,
  message,
  callback
})

export const addSiteSuccess = message => ({
  type: POST_ADD_SITE_SUCCESS,
  payload: message,
})

export const addSiteFail = error => ({
  type: POST_ADD_SITE_FAIL,
  payload: error,
})

//update
export const upSite = (data, callback) => ({
  type: POST_UPDATE_SITE,
  data,
  callback
})

export const upSiteSuccesss = data => ({
  type: POST_UPDATE_SITE_SUCCESS,
  payload: data,
})

export const upSiteFails = error => ({
  type: POST_UPDATE_SITE_FAIL,
  payload: error,
})

//delete
export const delSite = id => ({
  type: POST_DELETE_SITE,
  id,
})

export const delSiteSuccesss = id => ({
  type: POST_DELETE_SITE_SUCCESS,
  payload: id,
})

export const delSiteFails = error => ({
  type: POST_DELETE_SITE_FAIL,
  payload: error,
})
// clockin and clock

export const addClock = message => ({
  type: POST_ADD_CLOCK,
  message,
})

export const addClockSuccess = message => ({
  type: POST_ADD_CLOCK_SUCCESS,
  payload: message,
})

export const addClockFail = error => ({
  type: POST_ADD_CLOCK_FAIL,
  payload: error,
})
// clockout and clock
export const upClock = message => ({
  type: POST_UP_CLOCK,
  message,
})

export const upClockSuccess = message => ({
  type: POST_UP_CLOCK_SUCCESS,
  payload: message,
})

export const upClockFail = error => ({
  type: POST_UP_CLOCK_FAIL,
  payload: error,
})

export const getClock = data => ({
  type: GET_CLOCK,
  payload: data
})

// get clock in concept
export const getClockSuccess = chats => ({
  type: GET_CLOCK_SUCCESS,
  payload: chats,
})

export const getClockFail = error => ({
  type: GET_CLOCK_FAIL,
  payload: error,
})

export const delAttc = id => ({
  type: POST_DELETE_ATTC,
  id,
})

export const delAttcSuccesss = id => ({
  type: POST_DELETE_ATTC_SUCCESS,
  payload: id,
})
export const delAttcFails = error => ({
  type: POST_DELETE_ATTC_FAIL,
  payload: error,
})

//Get CheckIn Status for Worker
export const getCheckIn = (id) => ({
  type: GET_CHECK_IN,
  payload: id,
})

export const getCheckInSuccess = data => ({
  type: GET_CHECK_IN_SUCCESS,
  payload: data,
})

export const getCheckInFail = error => ({
  type: GET_CHECK_IN_FAIL,
  payload: error,
})