import {
  post,
  del,
  get,
  patch,
  attchpost,
  attchpatch,
  dels,
} from "./api_helper"
import * as url from "./url_helper"

// Register Method
const addSite = data => attchpost(url.POST_SITE_API_URL, data)

// Login Method
const getSites = data => post(url.GET_SITE_API_URL, data)

// postForgetPwd
// const deleteSite = data => del(url.POST_SITE_API_URL, data)

const deleteSites = id => del(`${url.DELETE_SITE_API_URL}?id=${id}`)

const upSites = data => attchpatch(url.UPDATE_SITE_API_URL, data)

// UserGetlist
const getUser = data => post(url.GET_USER_API_URL, data)

// const addUser = data => post(url.POST_USER_API_URL, data)

const addUser = data => post(url.POST_USER_API_URL, data)

const upUser = id => attchpatch(url.UPDATE_USER_API_URL, id)

const delUser = id => del(`${url.DELETE_USER_API_URL}?id=${id}`)

// Gedt roles and type in database
const getRoles = data => get(url.GET_ROLES_API_URL)

const getTypes = data => get(url.GET_TYPES_API_URL)

// Attachements User
const attachementUser = message =>
  attchpost(url.POST_ATTACHEMENT_API_URL, message)

// Forget User
const forgetUser = username => patch(`${url.POST_FORGET_API_URL}/${username}`)

const tokenUser = id => patch(`${url.POST_TOKEN_API_URL}?token=${id}`)

const resetUser = data => patch(url.POST_RESET_API_URL, data)

const addClocks = data => post(url.POST_CLOCK_API_URL, data)

const upClocks = data => patch(url.POST_CLOCKUP_API_URL, data)

const getClock = data => post(url.GET_CLOCK_API_URL ,data)

const delAttcs = id => dels(url.DELETE_ATTC_API_URL, id)

const delUserAttcs = id => dels(url.DELETE_ATTC_USER_API_URL, id)

const addnotifys = data => attchpost(url.POST_NOTIFICATION_API_URL, data)

const getCheckInStatus = data => get(`${url.GET_CHECK_IN_API_URL}?userid=${data}`)

export {
  addSite,
  getSites,
  // deleteSite,
  getUser,
  addUser,
  upUser,
  delUser,
  upSites,
  deleteSites,
  getRoles,
  getTypes,
  attachementUser,
  forgetUser,
  resetUser,
  tokenUser,
  getClock,
  addClocks,
  delAttcs,
  delUserAttcs,
  upClocks,
  addnotifys,
  getCheckInStatus
}
